import { https } from './https';
import { api_url } from '../Configuration/config';

export async function getAdmins(){
    return await https.get(`${api_url}admin/all`);
}
export async function getAdmin(id){
    return await https.get(`${api_url}admin/${id}`);
}

export async function updateAdmin(id,data){
    return await https.post(`${api_url}admin/${id}/update`, data);
}

export async function createAdmin(data){
    return await https.post(`${api_url}admin/create`, data);
}

export async function deleteAdmin(id){
    return await https.post(`${api_url}admin/${id}/destroy`);
}