import React ,{ useState , useEffect , useRef} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import { supportedLanguages } from '../../../utilities/SupportedLanguages';
import removeIcon from "../../../assets/icons/removeIcon.svg";
import uploadImage from "../../../assets/icons/uploadimage.svg";
/* import arrow from "../../../assets/images/ExpandArrow.png";
import langIcon from "../../../assets/images/language.png"; */
import removeTag from "../../../assets/icons/removetag.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Tooltip from '@mui/material/Tooltip';
import { t } from 'i18next';
import 'react-phone-input-2/lib/style.css';
import obj from './Model';
import { setSectionData } from './FormHandler';
import { getMainSections, getSection, getSectionPerants, getSubSections } from '../../../Apis/sections';
import { filterLanguage } from '../../../utilities/filterLang';
import i18n from 'i18next';
import { basename } from '../../../Configuration/config';
import '../../forms.scss';
import { Dialog } from '@mui/material';
import CropEasy from './../../../utilities/CropEasy';

export default function Form(props) {
    const { pathname } = useLocation();
    let navigate = useNavigate()
    const [model, setModel] = useState(obj);
    const [loading, setLoading] = useState(false); 
    const errorDivRef = useRef();
    const metaKeywordsRef = useRef();
    const [keywords , setKeywords] = useState({});
    /* const [selectedLanguage ,setSelectedLanguage] = useState('en'); */
    /* const languageRef = useRef(); */
    const imgRef = useRef();
    const secRef = useRef();
    const {id} = useParams();
    const [imageFlag ,setImageFlag] = useState(false);
    const [sideSections , setSideSections] = useState([]);
    const [errors, setErrors]= useState({});
    const [mainSecFlag ,setMainSecFalg] = useState(true);
    const [parents ,setParents] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        getMainSections().then((res)=>{
            let sub = [...sideSections];
            sub.push({sections:res.data.data ,id: Date.now()});
            if(res.data.data.length >= 6){
                setMainSecFalg(false)
            }
            setSideSections(sub);
        })
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(id){
            getSection(id).then((res)=>{
                let x = {...res.data.data};
                if(x.status === 'active'){
                    document.getElementById('statusCheck').checked = true;
                }
                let thiskeywords = {...keywords};
                supportedLanguages.map((language)=>{
                    let lang = filterLanguage(x.locales, language.slug);
                    if(lang.length){
                        if(lang[0].meta_keywords && lang[0].meta_keywords !== 'null'){
                            thiskeywords[language.slug] = lang[0].meta_keywords.replace(/ /g, '').split(',');
                        }
                        setKeywords(thiskeywords);
                    }
                    return true
                })
                setPhotoURL(x.image.url);
                getSectionPerants(id).then((res)=>{
                    setParents(res.data.data);
                })
                setModel(x)
            });
        }else{
            setModel({
                locales: [],
                section:'',
                status: 'inactive',
                image: '',
            })
        }
        // eslint-disable-next-line
    }, [id]);

    /********************* meta keywords ************************/
    function AddKeywordHandler(e,lang) {
        let propKeyword = {...keywords};
        if(e.target.value.length > 30 ){
            if(e.keyCode !== 8){
                e.preventDefault();
            }
        }
        if(e.keyCode === 32 ){
            if(!propKeyword[lang]){ 
                propKeyword[lang]=[]
            } 
            if(e.target.value.trim()){
                propKeyword[lang]=[...propKeyword[lang],e.target.value.trim()];
                setKeywords(propKeyword)
                e.target.value = ""; 

                let m ={...model}
                let existsLocales = m.locales.filter((i) => {
                    return i.locale === lang;
                })
                if(existsLocales.length){
                    existsLocales[0]['meta_keywords'] =  propKeyword[lang].join();
                }else{
                    m.locales.push({'locale': lang});
                    m.locales.map((i) => {
                        if(i.locale === lang){
                            i['meta_keywords'] =  propKeyword[lang].join();;
                        }
                        return true;
                    });
                }
            }
        }  
    
    }

    /* to delete tag to tags array*/
    function removeKeywordHandler(i,lang) {
        let propKeyword = {...keywords};
        /* JSON.parse(JSON.stringify(keywords)) */
        propKeyword[lang].splice(i,1)
        setKeywords(propKeyword) 
        let m ={...model}
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        })
        if(existsLocales.length){
            existsLocales[0]['meta_keywords'] =  propKeyword[lang].join();
        }else{
            m.locales.push({'locale': lang});
            m.locales.map((i) => {
                if(i.locale === lang){
                    i['meta_keywords'] =  propKeyword[lang].join();;
                }
                return true;
            });
        }
        setModel(m)
    }

    /* *********************** images and crop *********************** */
    //delete selected image
    function deleteImageHandler(file){
        let thisModel = {...model};
        thisModel.image = '';
        setModel(thisModel);
    }
    const [openCrop, setOpenCrop] = useState(false);
    const [file, setFile] = useState(null);
    const [photoURL, setPhotoURL] = useState();

    const handleChange = (e) => {
        const file = e.target.files[0];
        if(file){
            setPhotoURL(URL.createObjectURL(file));
            setOpenCrop(true);
            setOpen(true);
            e.target.value = null;
        }
    };

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(()=>{
        if(file){
            let thisModel = {...model}
            let srcObj = {file:file , url:photoURL}
            setImageFlag(true)
            thisModel['image'] = srcObj;
            setModel(thisModel);
        }
        //eslint-disable-next-line
    },[file])

    /**************************** form onChange function *********************************/ 
    const setFormData = async(e ,property ,locale=false ,language=null) => {
        setSectionData(model, setModel, e, property , locale , language );
        if(property === 'section'){
            let sectionId = e.target.value;
            let sub = [...sideSections];
            let index = Number(e.target.getAttribute('data-i'));
            if((index+1)<=(sub.length-1)){
                sub.splice(index+1,sub.length-1);
                /* setSideSections(sub); */
            }
            secRef.current.classList.add("d-none");
            setTimeout(function(){
                if(sectionId !== '' || sectionId !== 'main')  {
                    getSection(sectionId).then((res)=>{
                        let section= res.data.data;
                        if(section?.sections.length){
                            getSubSections(sectionId,'',id).then((res)=>{
                                sub.push({sections:res.data.data ,id: Date.now()});
                                setSideSections(sub); 
                            })
                        }else{
                            setSideSections(sub); 
                        }
                    })
                }else{
                    setSideSections(sub);
                }
            },400)
        }
        let err = { ...errors }
        err[e.target.name] = '';
        setErrors(err)
    }

    function preventEnter(e){
        if(e.keyCode===13){
            e.preventDefault();
        }
    }

    async function confirmSection(e){
        e.preventDefault();
        let formData = new FormData();
        let count = 0;
        let thisModel = {...model}
        supportedLanguages.map((language)=>{
            let name = Array.from(document.querySelectorAll(`.requiredName${language.slug}`))[0];
                if(name.value.trim() ){
                    count ++;
                }else{
                    if(!name.value.trim()){
                        let input = Array.from(document.querySelectorAll(`.requiredName${language.slug}`));
                        input[0].nextSibling.classList.remove("d-none");
                        window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX ,
                        input[0].getBoundingClientRect().top + window.scrollY - 150); 
                    }
                }
            return true
        })
        if(!thisModel.image){
            imgRef.current.nextSibling.classList.remove("d-none");
        }
        if(thisModel.section === ''){
            secRef.current.classList.remove("d-none");
        }
        if(thisModel.image && (thisModel.section !== '' ) && (count === supportedLanguages.length)
        ){
            setLoading(true); 
            if(!imageFlag){
                delete thisModel.image
            }
            delete thisModel.type
            if(thisModel.section === 'main'){
                thisModel.type = 'main';
                delete thisModel.section;
            }
            for(const key in thisModel){
                if (key === 'locales'){
                    for (let i = 0; i < thisModel.locales.length; i++){
                        for (const key2 in thisModel.locales[i]){
                            if(thisModel.locales[i][key2] !== null && thisModel.locales[i][key2] !== ''){
                                formData.append(`locales[${i}][${key2}]`, thisModel.locales[i][key2]);
                            } else{
                                formData.append(`locales[${i}][${key2}]`, '');
                            }
                        }
                    }
                }else if(key === 'image'){
                    formData.append(key,thisModel.image.file);          
                }else{
                    if(thisModel[key] !== '' && thisModel[key] !== null){
                        formData.append(key, thisModel[key]);
                    }else{
                        if(key !== 'section'){
                            formData.append(key, '');
                        }
                    }
                }
            }
            /* for (var pair of formData.entries()){
                console.log(pair[0]+ ', ' + pair[1]); 
            } */
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if(submitProps === 'done'){
                setLoading(false);
                if(!thisModel.section){
                    navigate(`${basename}/dashboard/sections/main`);
                }else{
                    navigate(`${basename}/dashboard/sections/${thisModel.section}/sub-sections`);
                }
            }else{
                setLoading(false);
                setErrors(submitProps);
            }
        }
    }

    return (
        <div>
            <section className='componentGlobalWrapper'>
                <form onKeyDown={preventEnter} onSubmit={(e) => confirmSection(e, model)} >
                        {supportedLanguages.map((language, index)=>(
                            <div key={index}>
                                {/** name **/}
                                <div  className='formWidth'>
                                    <div className='d-flex flex-column mb-4'>
                                        <label htmlFor='name' className='formLabel'>{t('name')} ({t(language.name)}) <span className='text-danger'>*</span></label>
                                        <input id='name' maxLength='255' className={`requiredName${language.slug}`} name="name" type='text' placeholder={t("title")} value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,language.slug).length) ? filterLanguage(model.locales ,language.slug)[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , language.slug)} />
                                        <div className='validateToDelete text-danger d-none'>
                                        {t("this-field-is-required!")}
                                        </div>
                                    </div>
                                </div>
                                {/** meta keywords  ***/}
                                <div className='row m-0 p-0 mb-4 formWidth' >
                                    <div className='col-6 m-0 p-0'>
                                        <div className='flex-column marginEnd'>
                                            <label htmlFor='metaKeyword' className='formLabel'>{t('meta-keywords')} ({t(language.name)})</label>
                                            <div ref={metaKeywordsRef} className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                                                <div className="">
                                                    {
                                                        keywords[language.slug] && keywords[language.slug].map((keyword,index)=>(
                                                            <div key={index} className={`tagContainer`}>
                                                                {keyword}
                                                                <img src={removeTag} onClick={() => removeKeywordHandler(index ,language.slug)}className='removeTag' alt='remove' />
                                                            </div>
                                                        ))
                                                    }
                                                    <input onKeyDown={(e)=>AddKeywordHandler(e,language.slug)} maxLength='30' type="text" name="metaKeyword" className={`tagInput border-0`}  id="tags" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <div className='col-6 m-0 p-0'>
                                        <div className='flex-column w-100 marginStart'>
                                            <label htmlFor='meta-desription' className='formLabel'>{t('meta-description')} ({t(language.name)})</label>
                                            <input maxLength='255' className='w-100' name="meta_description" value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,language.slug).length) ? filterLanguage(model.locales ,language.slug)[0].meta_description : ""} onChange={(e) => setFormData(e, 'meta_description' , true , language.slug)}/>
                                        </div>
                                    </div>
                                </div>
                                
                            </div> 
                        ))}
                    {/* </div> */}
                    {id && <div className='row m-0 p-0 mb-4 formWidth'>
                        <div className='col-6 m-0 p-0'>
                            <div className='d-flex flex-column marginEnd'>
                                <label htmlFor='name' className='formLabel'>{t('order')} <span className='text-danger'>*</span></label>
                                <input id='name' min={1}  name="order" type='number' placeholder={t("order")} value={model && model.order} onChange={(e) => setFormData(e, 'order')} />
                                {errors?.order && errors.order.length && <div className='validateToDelete text-danger'>
                                    {errors.order}
                                </div>}
                            </div>
                        </div>
                    </div>}
                    <div className='formWidth'>
                        <div className='selectForm'>
                            {/* sections */}
                            <div className='d-flex flex-column mb-4 w-100'>
                                <label className='formLabel'>{t('section')} <span className='text-danger'>*</span></label>
                                {id && parents && parents.map((sec)=>{
                                    return `${sec} / `
                                })}
                                
                                {/* parent section */}
                                {sideSections ? sideSections.map((sec,i)=>(
                                    <div className='selectParent mt-2' key={sec.id}>
                                        <select data-i={i} defaultValue={0} name='section' placeholder={t('section')} onChange={(e) => setFormData(e, 'section')}>
                                            <option value={0} disabled >{id?t('update-section'):t('select-section')}</option>
                                            {mainSecFlag && i === 0 && <option value='main'>{t('main-section')}</option>}  
                                            {sec.sections && sec.sections.map((section , index)=>{ 
                                                let language = i18n.language;
                                                let locale = filterLanguage(section.locales ,language);
                                                return <option value={section.id} key={index}>{locale[0]?.name ? locale[0]?.name : section.locales[0]?.name}</option>
                                            })}
                                        </select>
                                    </div>)
                                )
                                :''}
                                {errors?.section && errors.section.length && <div className='validateToDelete text-danger '>
                                    {errors.section}
                                </div>}
                                <div ref={secRef} className='validateToDelete text-danger d-none'>
                                    {t('this-field-is-required!')}  
                                </div>
                        
                            </div>
                        </div>
                        
                    </div>

                    <div  className='formWidth'>
                        {/*** images ***/}
                        <div className="row m-0 mb-5 d-flex align-items-center ">
                        <label className='formLabel p-0 mb-0'>{t('image')} <span className='text-danger'>*</span></label>
                            {model?.image ?
                                <div  className='globalImageWrapper position-relative p-1 mt-2'>
                                    <Tooltip title='Remove Image'>
                                        <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                            <img src={removeIcon} alt="delete" onClick={()=>deleteImageHandler()}/>
                                        </label>
                                    </Tooltip>
                                    <div className='globalPreviewedImage' style={{backgroundImage: `url(${photoURL})` , 'backgroundSize': 'cover', 'width' : '100%' , 'height' : '100%'}}></div>
                                </div>
                                :
                                <div className='px-0'>
                                    <div ref={imgRef} className='imageWrapper p-0 mt-2' style={{"width" : "180px" , "height" : "140px"}}>
                                        <label htmlFor='propertyImg' className='h-100 w-100 d-flex flex-column justify-content-center align-items-center'>
                                            <img width='90px' height='80px' src={uploadImage} alt='upload' />
                                            <input onChange={(e) => handleChange(e)}  name="image" type="file" className={`d-none`} id="propertyImg"/>
                                            <span> {t('upload-image')} </span>
                                        </label>
                                    </div>
                                    <div className='text-danger mt-1 d-none validateToDelete'>
                                        {t('this-field-is-required!')}
                                    </div>
                                </div>
                            }
                            {
                                errors && errors['image'] ?<div className='text-danger validateToDelete'>{errors['image']}</div> :''
                            }
                            
                        </div>

                        {/* { errors && errors.images && <div className='text-danger validateToDelete'>{errors.image}</div> } */}

                    {/** activate article ***/}
                        <div className='d-flex mb-4'>
                            <div className='d-flex align-items-center'>                        
                                <label className="switch mb-0">
                                    <input type="checkbox" name='status'
                                        id='statusCheck' onChange={(e) => setFormData(e, 'status')} />
                                    <span className="slider round" />
                                </label>
                                <label htmlFor='active_status' className='formLabel mb-0 labelForCheck'>{t('active')}</label>
                            </div>
                        </div>

                        <div ref={errorDivRef} className="row mb-4 d-none">
                            <div className='alert alert-danger validateToDelete'>
                                {t('you-should-enter-the-article-data-in-one-language-at-least!')}
                            </div>
                        </div>

                        {/* loader and submit btn */}
                        <div>
                            {!loading?
                                <button className='confirmBtn propSubmit' type='submit'>{id ? t('save-changes') : t('add-article')}</button>
                                :
                                <button className='confirmBtn' disabled>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    {t('saving')}...
                                </button>
                            }
                        </div>
                    </div>
                </form>
            </section>
            <div>
                {openCrop && 
                    <Dialog open={open} onClose={handleClose}>
                        <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />
                    </Dialog>
                }
            </div>
        </div>
    )
}
