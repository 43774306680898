import { t } from 'i18next'
import React ,{ useState , useEffect} from 'react'
import { useLocation } from 'react-router';
import { getHomeSlider, updateHomeSlider } from '../../../Apis/homePage';
import { supportedLanguages } from './../../../utilities/SupportedLanguages';
import '../../forms.scss'
import { filterLanguage } from './../../../utilities/filterLang';

export default function HomeImage() {
    const [loader, setLoader]= useState(false);
    const [data, setData] = useState({locales:[]});
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);

        getHomeSlider().then((res)=>{
            let x = res.data.data;
            let thisData = {...data};
            thisData.locales = x.locales;
            setData(thisData);
        })
        // eslint-disable-next-line
    }, [pathname]);
    

    function changeHandler(e,language){
        let thisData = {...data};
        let exist = thisData.locales.filter((obj)=>obj.locale === language)[0];
        if(exist){ 
            exist[e.target.name] = e.target.value;
        }else{
            thisData['locales'].push({'locale':language});
            thisData.locales.map((obj)=>{
                if(obj.locale === language){
                    obj[e.target.name] = e.target.value;
                }
                return true
            })
        }
        setData(thisData); 
    }

    function submit(e){
        e.preventDefault();
        setLoader(true);
        let formData = new FormData();
        let thisData = {...data}
        for(const key in thisData){
            if(key === 'locales'){
                for(const lang in thisData[key]){
                    for(const key2 in thisData[key][lang]){
                        formData.append(`locales[${lang}][${key2}]`,thisData[key][lang][key2]);
                    }
                }
            }else{
                formData.append(key, thisData[key]);
            }
        }
        /* for (var pair of formData.entries()){
            console.log(pair[0]+ ', ' + pair[1]); 
        } */
        updateHomeSlider(formData).then((res)=>{
            setLoader(false);
        }).catch((err)=>{
            setLoader(false);
        })
    }

    return (
        <div className='componentGlobalWrapper'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{t('home-page')}</h5>
                <form className='pagesForm'  onSubmit={submit} >
                    {supportedLanguages?.map((lang ,i)=>{
                        return <div key={i}>
                                    <div className='d-flex flex-column mb-4'>
                                        <label className='formLabel'>{t('title')} ({t(`${lang.name}`)})</label>
                                        <input name="title" type='text' value={(data?.locales && filterLanguage(data.locales ,lang.slug)[0]) ? filterLanguage(data.locales ,lang.slug)[0].title : ''}   onChange={(e) => changeHandler(e,lang.slug)} />
                                    </div>
                                    
                                </div>
                    })} 
                    {!loader ?
                            <div>
                                <button className='confirmBtn' type='submit' >
                                    {t('save-changes')}
                                </button>
                            </div>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {t('saving')}...
                            </button>
                        }
                </form>
            </div>
        </div>
    )
}
