import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getRoles(){
    return await https.get(`${api_url}admin/roles`);
}

export async function getAllPermissions(){
    return await https.get(`${api_url}admin/roles/permissions`);
}

export async function getRole(id){
    return await https.get(`${api_url}admin/roles/${id}`);
}

export async function deleteRole(id){
    return await https.post(`${api_url}admin/roles/${id}/destroy`);
}

export async function updateRole(id, data){
    return await https.post(`${api_url}admin/roles/${id}/update`, data);
}

export async function createRole(data){
    return await https.post(`${api_url}admin/roles/create`, data);
}