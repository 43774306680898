export const setAdminData = (model, setModel, ele, property) =>{
    let newData = Object.assign({}, model);

    if (ele.target.name === 'image') {
        if(ele.target.files.length){
            newData['image'] = ele.target.files[0];
        }
    }else{
        newData[property] = ele.target.value;
    }
    setModel(newData);
}