import {https} from './https';
import {api_url} from '../Configuration/config';

//get logged in admin profile info
export async function getAdminProfileInfo() {
    // let admin = localStorage.getItem('admin')
    // if (admin)
    //     return JSON.parse(admin);
    let data = await https.get(`${api_url}admin`);
    localStorage.setItem('admin', JSON.stringify(data));
    return data;
}

//edit logged in admin profile info
export async function editAdminProfileInfo(data) {
    let res = await https.post(`${api_url}admin/update`, data);
    localStorage.setItem('admin', JSON.stringify(data));
    return res;
}

