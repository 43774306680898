import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { basename } from '../../Configuration/config';
import authImg from '../../assets/images/authImg.jpg';
import Login from '../../Components/Login/Login';
import ResetPassword from '../../Components/Login/ResetPassword';
import './auth.scss';

export default function AuthLayout() {
    return (
        <div className = 'position-relative' >
            <div className="login-bg-img">
                <section className='login-wrapper'>
                    <img className='login-form-image d-none d-md-block' src={authImg}  alt="login form"/>
                    <div className='login-form'>
                        <div className='formHeader'>
                            {/* <img src={logo} alt="logo"/> */}
                            <h1>Tourism</h1>
                        </div>
                            <Routes>
                                <Route path={`${basename}/`} element={<Login />} />
                                <Route path={`${basename}/reset-password`} element={<ResetPassword />} />
                                <Route path={`${basename}/*`} element={<Login />} /> 
                            </Routes> 
                    </div>
                </section>
            </div>
            <div className='login-bg-layer'></div>
        </div>
    )
}
