export const setRoleData = (model, setModel, ele, property) =>{
    let newData = Object.assign({}, model);
    if(property === 'name'){
        newData[ele.target.name] = ele.target.value;
    }else{
        if(ele.target.checked) {
            newData.permission_ids.push(Number(ele.target.value));
        }else {
            let x = newData.permission_ids.indexOf(Number(ele.target.value));
            newData.permission_ids.splice(x, 1)
        }
    }
    setModel(newData);
}