import React, { useContext, useEffect, useRef, useState } from 'react';
import TitleContext from '../../Contexts/TitleContext';
import removeTag from "../../assets/icons/removetag.svg";
import "./GeneralSettings.scss";
import { useLocation } from 'react-router';
/* import uploadImage from "../../assets/icons/uploadimage.svg";
import deleteIcon from "../../assets/icons/removeIcon.svg";
import Tooltip from '@mui/material/Tooltip'; */
import { getGeneralSettings, updateGeneralSettings } from '../../Apis/generalSettings';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { supportedLanguages } from './../../utilities/SupportedLanguages';
import { filterLanguage } from './../../utilities/filterLang';

export default function EditGeneralSettings() {
    let {changeTitle} = useContext(TitleContext);
    const {i18n} = useTranslation(); 
    const [model, setModel] = useState({});
    /* let [favIcon , setFavIcon] = useState('');
    let [logoImage , setLogoImage] = useState('');
    const favIconRef = useRef();
    const logoRef = useRef(); */
    const { pathname } = useLocation();
    const [loader , setLoader] = useState(false);
    const metaKeywordsRef = useRef();
    const [keywords , setKeywords] = useState({});
    /* const [favIconFlag ,setFavIconFlag] = useState(false);
    const [logoFlag ,setLogoFlag] = useState(false); */


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        changeTitle(t(`general-settings`));
        getGeneralSettings().then((res) => {
            let data = res.data.data;
            delete data.terms_and_conditions_image;
            delete data.privacy_policy_image;
            delete data.terms_and_conditions;
            delete data.privacy_policy;
            delete data.favicon;
            delete data.logo;
            delete data.facebook;
            delete data.email;
            delete data.twitter;
            delete data.linkedin;
            delete data.instagram;
            delete data.phone;
            /* setFavIcon(data.favicon);
            setLogoImage(data.logo); */
            setModel(data);
            let thisKeywords = {...keywords};
            supportedLanguages.map((language) => {
                let obj = filterLanguage(data.meta_keywords, language.slug);
                if(obj.length) {
                    if(obj[0].name && obj[0].name !== 'null'){
                        thisKeywords[language.slug] = obj[0]?.name.replace(/ /g, '').split(',');
                    }
                }
                return true
            })
            setKeywords(thisKeywords);
            /* data?.meta_keywords ? setKeywords(data.meta_keywords.replace(/ /g, '').split(',')): setKeywords([]); */
        })
        // eslint-disable-next-line
    }, [i18n.language]);

    /****************** set keywords ************************/
    function AddKeywordHandler(e,language) {

        if(e.keyCode === 32 ){
            if(e.target.value.trim()){
                let thisKeywords = {...keywords};
                if(!thisKeywords[language]){ 
                    thisKeywords[language]=[]
                } 
                thisKeywords[language]=[...thisKeywords[language],e.target.value.trim()];
                setKeywords(thisKeywords);
                e.target.value = ""; 

                let thisModel = {...model};
                let exist = thisModel[e.target.name].filter((key)=>key.locale === language)[0];
                    if(exist){
                        exist.name = thisKeywords[language].join(',');
                    }else{
                        thisModel[e.target.name][language] = {name:thisKeywords[language].join(',')};
                    }
                setModel(thisModel);
            }
        }  
    }

    /* to delete tag to tags array*/
    function removeKeywordHandler(i,language) {
        let thisKeywords = {...keywords};
        thisKeywords[language].splice( i , 1 );
        setKeywords(thisKeywords);
        let thisModel = {...model};
        let exist = thisModel['meta_keywords'].filter((key)=>key.locale === language)[0];
            if(exist){
                exist.name = thisKeywords[language].join(',');
            }else{
                thisModel['meta_keywords'][language] = {name:thisKeywords[language].join(',')};
            }
        setModel(thisModel);
    }

    function changeHandler(e,language){
        let thisModel = {...model};
        /* if(e.target.name === 'favicon'){
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setFavIcon(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
            thisModel[e.target.name] = e.target.files[0];
            setFavIconFlag(true);
        } */
        //  else if(e.target.name === 'logo'){
        //     const reader = new FileReader();
        //     reader.addEventListener("load", () => {
        //         setLogoImage(reader.result);
        //     });
        //     reader.readAsDataURL(e.target.files[0]);
        //     thisModel[e.target.name] = e.target.files[0];
        //     setLogoFlag(true);
        //     /* setLogoImage(URL.createObjectURL(e.target.files[0])); */
        // } 
       /*  else{ */

        let exist = thisModel[e.target.name].filter((key)=>key.locale === language)[0];
        if(exist){
            exist.name = e.target.value;
        }else{
            thisModel[e.target.name][language] = {name:e.target.value,locale:language};
        }
        // }
        setModel(thisModel);
    }

    /* function removeImage(name){
        if(name === 'favicon'){
            setFavIcon('');
        }else if(name === 'logo'){
            setLogoImage('');
        }
    }
 */
    
    function onConfirmEdit(e){
        e.preventDefault();
        /*  if(!favIcon){
            window.scrollTo(favIconRef.current.getBoundingClientRect().left + window.scrollX ,
            favIconRef.current.getBoundingClientRect().top + window.scrollY - 150);
            favIconRef.current.nextSibling.classList.remove("d-none");
        } 
        if(!logoImage){
            window.scrollTo(logoRef.current.getBoundingClientRect().left + window.scrollX ,
            logoRef.current.getBoundingClientRect().top + window.scrollY - 150);
            logoRef.current.nextSibling.classList.remove("d-none");
        }  */
        /* if(logoImage && favIcon){ */
        let formData = new FormData();
        let thisModel = {...model};
        setLoader(true);
        /* if(!favIconFlag){
            delete thisModel['favicon'];
        }
        if(!logoFlag){
            delete thisModel['logo'];
        } */
        for(const key in thisModel){
            if(thisModel[key] instanceof Array){
                for(let i=0 ;i<= thisModel[key].length ;i++){
                    for( const key2 in thisModel[key][i]){
                        formData.append(`${key}[${i}][${key2}]`,thisModel[key][i][key2]);
                    }
                }
            }else{
                formData.append(key, thisModel[key] ? thisModel[key]:'');
            }
        }
        /* for (var pair of formData.entries()){
            console.log(pair[0]+ ', ' + pair[1]); 
        } */

        updateGeneralSettings(formData).then((res)=>{
            setLoader(false);
        }).catch((err)=>{
            setLoader(false);
        })
       /*  } */
    }

    function preventEnter(e) {
        if(e.keyCode===13){
            e.preventDefault();
        }
    }

    return (
        <>
        <section className='componentGlobalWrapper generalSettings addBlog' >
            <div className='mb-5'>
                <h5 className='pageSubtitle'>{t('site-settings')}</h5>
            </div>
            {model?
                <form onSubmit={onConfirmEdit} onKeyDown={preventEnter}>
                    <div className='generalSettingsForm'>
                        <div className='d-flex justify-content-between align-items-start mb-2'>
                            {/** data container  ***/}
                            <div className='formWidth'>
                                {supportedLanguages.map((language)=>{
                                    return (
                                        <div>
                                            {/*** site name ****/}
                                            <div className='d-flex flex-column mb-4'>
                                                <label htmlFor={`site_name`} className='formLabel'>{t(`site-name`)} ({t(language.name)})</label>
                                                <input type='text' onChange={(e)=>changeHandler(e,language.slug)} maxLength='250' minLength='3' name="site_name" id={`site_name`} value={model?.site_name && filterLanguage(model?.site_name,language.slug)[0]?.name} />
                                            </div>
                                            {/*** site tagline ****/}
                                            <div className='d-flex flex-column mb-4'>
                                                <label htmlFor={`tagline`} className='formLabel'>{t(`site-tagline`)} ({t(language.name)})</label>
                                                <input type='text' onChange={(e)=>changeHandler(e,language.slug)} maxLength='250' minLength='3' name="tag_line" id={`tagline`} value={model?.tag_line && filterLanguage(model?.tag_line,language.slug)[0]?.name} />
                                            </div> 
                                            {/** meta keywords  ***/}
                                            <div className='d-flex flex-column mb-4' >
                                                <label htmlFor='metaKeyword' className='formLabel'>{t('meta-keywords')} ({t(language.name)})</label>
                                                <div ref={metaKeywordsRef} className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                                                    <div className="">
                                                    {
                                                        keywords && keywords[language.slug] && keywords[language.slug].map((keyword,index)=>(
                                                            <div key={index} className={`tagContainer`}>
                                                                {keyword}
                                                                <img src={removeTag} onClick={() => removeKeywordHandler(index ,language.slug)} className='removeTag' alt='remove' />
                                                            </div>
                                                        ))
                                                    }
                                                    <input onKeyDown={(e)=>AddKeywordHandler(e,language.slug)} maxLength='30'type="text" name="meta_keywords" className={`tagInput border-0`}  id="tags" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/*** meta description ***/}
                                            <div className='d-flex flex-column mb-4'>
                                                <label htmlFor={`meta-description`} className='formLabel'>{t(`meta-description`)} ({t(language.name)})</label>
                                                <textarea maxLength='250' onChange={(e)=>changeHandler(e,language.slug)} minLength='3' name="meta_description" id={`meta-description`} type='text' value={model?.meta_description && filterLanguage(model?.meta_description,language.slug)[0]?.name} />
                                            </div>
                                        </div>
                                    )})
                                }
                            </div>
                            {/* images container */}
                            <div>
                                {/** favicon **/}
                                {/*<div className='d-flex flex-column mb-4'>
                                    <label htmlFor='favicon' className='formLabel'>{t('favicon')}</label>
                                    { !favIcon ?
                                        <div>
                                            <label ref={favIconRef} htmlFor="uploadImage" className='position-relative'>
                                                <div className='globalImageWrapper'>
                                                    <input onChange={changeHandler} type='file' id='uploadImage' name='favicon' className="d-none"/>
                                                    <img className='cloudIcon' src={uploadImage} alt="upload"/>
                                                    <span>{t('upload-image')}</span>
                                                </div>
                                            </label>
                                            <div className='validateToDelete text-danger d-none'>{t('this-field-is-required!')}</div>
                                        </div>
                                        :
                                        <div className='globalImageWrapper position-relative'>
                                            <Tooltip title='Remove Image'>
                                                <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                                    <img src={deleteIcon} alt="delete" onClick={()=>removeImage('favicon')}/>
                                                </label>
                                            </Tooltip>
                                            <div className='globalPreviewedImage' style={{backgroundImage: `url(${favIcon})` , 'backgroundSize': 'contain', 'width' : '60%' , 'height' : '60%'}}></div>
                                        </div>
                                    }
                                </div>*/}
                                
                                {/** logo **/}
                                {/*<div className='d-flex flex-column mb-4'>
                                    <label htmlFor='logoimage' className='formLabel'>{t('logo')}</label>
                                    { !logoImage ?
                                        <div>
                                            <label ref={logoRef} htmlFor="uploadImage" className='position-relative'>
                                                <div className='globalImageWrapper'>
                                                    <input onChange={changeHandler} type='file' id='uploadImage' name='logo' className="d-none"/>
                                                    <img className='cloudIcon' src={uploadImage} alt="upload"/>
                                                    <span>{t('upload-image')}</span>
                                                </div>
                                            </label>
                                            <div className='validateToDelete text-danger d-none'>{t('this-field-is-required!')}</div>
                                        </div>
                                        :
                                        <div className='globalImageWrapper position-relative'>
                                            <Tooltip title='Remove Image'>
                                                <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                                    <img src={deleteIcon} alt="delete" onClick={()=>removeImage('logo')}/>
                                                </label>
                                            </Tooltip>
                                            <div className='globalPreviewedImage' style={{backgroundImage: `url(${logoImage})` , 'backgroundSize': 'contain', 'width' : '60%' , 'height' : '60%'}}></div>
                                        </div>
                                    }
                                </div>*/}
                            </div>
                        </div>
                        
                        {!loader ?
                            <div>
                                <button className='confirmBtn' type='submit'>{t('save-changes')}</button>
                            </div>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {t('saving')}...
                            </button>
                        }
                    </div>
                </form> 
                : ''
            }
        </section>
        </>
    )
}
