import React from 'react';
import "../AddRole.scss";
import { useParams } from 'react-router';
import formModel from './formModel';
import { useState } from 'react';
import { setRoleData } from './formHandler';
import { useEffect } from 'react';
import { deleteRole, getRole, getAllPermissions } from '../../../Apis/roles';
import DeleteModal from './../../../utilities/deleteModal';
import { t } from 'i18next';
/* import { api_basename } from './../../../Configuration/Config'; */

export default function Form(props){
    let { id } = useParams();
    const [model, setModel] = useState(formModel);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    /* const navigate = useNavigate(); */
    const [allpermissions, setallpermissions] = useState();
    //const [role, setRole] = useState({});

    function getPermissions(){
        getAllPermissions().then((res) => {
            let roles = { ...allpermissions }
            roles = res.data.data
            setallpermissions(roles)
        })
    }
    useEffect(() => {
        getPermissions()
        //eslint-disable-next-line
    }, [])
    
    useEffect(() => {
        if(id){
            getRole(id).then((res)=>{
                let thisRole = res.data.data;
                let thisModel = {name:thisRole.name,permission_ids:[...thisRole.permissions.map(item=>item.id)]}
                setModel(thisModel);
            })
        }else{
            setModel({
                    name:'',
                    permission_ids:[]
                }
            )
        }
        // eslint-disable-next-line
    },[id])

    // onchange
    const setFormData = (e, property , locale=false , language=null) => {
        setRoleData(model, setModel, e, property , locale , language);
    }

    // onsubmit
    async function confirmRole(e){
        e.preventDefault();
        let thisModel = {...model};
        setLoading(true);
        // send request
        let submitProps = await props.onSubmit(e, thisModel);
        if(submitProps === 'done'){
            setModel({
                locales: [],
                permissions:[]
            })
            setErrors({});
            setLoading(false);
        }else if(submitProps === 'done_update'){
            setErrors({});
            setLoading(false);
        }
        else{
            setLoading(false);
            setErrors(submitProps);
        }
    }

    function preventEnter(e) {
        if(e.keyCode===13){
            e.preventDefault();
        }
    }

    function selectAllHandler(e){
        let thisModel = {...model,permission_ids:[]};
        if(e.target.checked){
            allpermissions && Object.keys(allpermissions).map((section) => {
                allpermissions[section].map((permission) => {
                    thisModel.permission_ids.push(permission.id)
                    return true
                })
                return true
            })
        }else{
            thisModel.permission_ids = []
        }
        setModel(thisModel);
    }

    const onSubmitDelete = async ()=>{
        return await deleteRole(id).then((res) =>{
            props.getAll();
            return 'deleteRole';
        }).catch(err => {
            return err.response.data.message;
        })
    }
    return (
        <>
            <form onSubmit={confirmRole} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-between align-items-center mb-5'>
                    <h5 className='capitalWord'>{id ? t(`update-role`) : t(`add-role`)}</h5>
                    <div className='d-flex flex-wrap' style={{marginInlineStart:'35%'}}>
                        {id ?
                            <div style={{"width" : "fitContent" , "cursor" : "pointer"}} data-bs-toggle="modal" data-bs-target='#deleteModal' className='confirmBtn deleteBtn'>{t('delete')}</div>
                        : ''}
                        { !loading ?
                            <button className='confirmBtn' type='submit'>{id ? t(`save-changes`) : t(`add-role`)}</button>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {t('saving')}...
                            </button>
                        }
                    </div>
                </div>
                {/*** role title and description ***/}
                <div className='pagesForm'>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name' className='formLabel'>{t('name')}</label>
                        <input name="name" id='name' type='text' placeholder={t('name')} value={model?.name ? model.name  : ""} onChange={(e) => setFormData(e, 'name')} minLength={3} maxLength={20} required/>
                        {errors?.name && <div className='validateToDelete text-danger'>
                            {errors?.name}
                        </div>}
                    </div>
                </div>

                {/**** permissions ****/}
                <div className='permissionsWrapper'>
                    <div className='mb-5'>
                        <h5 className='capitalWord'>{t('permissions')}</h5>
                        {errors?.permission_ids && <div className='validateToDelete text-danger'>
                            {errors?.permission_ids}
                        </div>}
                    </div>
                    <div className='checkLabelWrapper'>
                        <input className='permissionsCheckbox' onChange={selectAllHandler} type="checkbox" id="all" name="all" defaultValue="Bike" />
                        <label htmlFor="all">{t('select-all')}</label>
                    </div>
                    <div>
                        {
                            allpermissions && Object.keys(allpermissions).map((section) => {
                                return (
                                <div className='mt-5' key={section}>
                                        <p className='mb-2'>{section}</p>
                                    <div className='row wow-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 m-0 p-0' key={section}>
                                            {allpermissions[section].map((permission) => {
                                            return (
                                                <div className='col mt-2' key={permission.name}>
                                                    <div key={permission.id} className='checkLabelWrapper'>
                                                        <input className='permissionsCheckbox' type="checkbox" name="permissions" value={permission.id} id={permission.id} onChange={(e) => setFormData(e, 'permissions')} {...(model?.permission_ids?.includes(Number(permission.id)) ? {'checked': true} :{}) }/>
                                                        <label htmlFor={permission.id}>
                                                            {permission?.display_name}
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                            })}
                                    </div>
                                </div>)
                            })
                            }
                    </div>
                </div>
            </form>
            <DeleteModal onSubmitDelete={onSubmitDelete} name='role'></DeleteModal>
        </>
    )
}
