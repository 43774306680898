import { https } from './https';
import { api_url } from './../Configuration/config';

//get settings
export async function getHomeSlider(){
    return await https.get(`${api_url}admin/slider-images/1`);
}

//update settings
export async function updateHomeSlider(data){
    return await https.post(`${api_url}admin/slider-images/1/update`, data);
}