import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import closedeye from "../../assets/icons/closeEye.svg";
import openeye from "../../assets/icons/openEye.svg";
import { basename } from '../../Configuration/config';
import { useNavigate } from "react-router";
import { adminLogin } from '../../Apis/login';
import { t } from 'i18next';

export default function Login() {
    let [loading,setLoading]=useState(false);
    let [error,setError]=useState('');
    let [passType,setPasstype]=useState('password');
    let [Admin , setAdmin] = useState({email: "", password: ""});
    let navigate = useNavigate();

    function adminHandler(e){
        let mainAdmin = {...Admin};
        mainAdmin[e.target.name] = e.target.value;
        setAdmin(mainAdmin);
    }

    function showPasswordHandler() {
        if (passType === 'password') {
            setPasstype('text');
        } else {
            setPasstype('password');
        }
    }

    function submitAdminLogin(e){
        e.preventDefault();
		setLoading(true);
        //to send request
        adminLogin(Admin).then((res) => {
            const Tourism_Admin_Token = res.data.data.token;
            localStorage.setItem('Tourism_Admin_Token' , Tourism_Admin_Token);
            navigate(`${basename}/dashboard/overview`); 
            setLoading(false);
            window.location.reload(); 
        }).catch(err => {
            setLoading(false);
            console.log(err)
            let error = err.response.data.message;
            setError(error);
        })
    }

    return (
        <form className='w-100' onSubmit={submitAdminLogin}>
        {error && <div className="alert alert-danger py-1">{error}</div>}
        <div className='d-flex flex-column mb-4'>
            <label htmlFor='username' className='formLabel'>{t('email')}</label>
            <input onChange={adminHandler} name="email" className='formInput' id='username' type='email' placeholder={t('email')} required />
        </div>
        <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between align-items-center'>
                <label htmlFor='pass' className='formLabel mb-0'>{t('password')}</label>
                <Link to={`${basename}/reset-password`} className='align-self-end forgetPass'>{t('forgot-password')}{t('?')}</Link>
            </div>
            <div className='position-relative'>
                <input onChange={adminHandler} name="password" className='formInput' id='pass' type={passType} placeholder={t('password')} minLength={8} required />
                <span onClick={showPasswordHandler} className={`eyeIcon`}  >
                    {passType === "password" ? <img src={closedeye} alt="eye icon"/>:
                        <img src={openeye} alt='eye icon'/>
                    }
                </span> 

            </div>

            <div className='d-grid mt-5'>
                {!loading ? 
                    <button type='submit' className='defaultBtn'>{t('login')} </button>
                    :
                    <button className='defaultBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        {t('loading')}...
                    </button>
                }
            </div>
        </div>
        </form>
    )
}
