import React from 'react'
import { createRole } from '../../../Apis/roles';
import Form from './Form';

export default function AddRole(props) {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await createRole(data).then(res => {
            props.getAll();
            console.log(res.data.data)
            return 'done';          
        }).catch(err =>{
            console.error(err.response);
            return err.response.data.errors;
        })
    };

    return(
        <div className='actionCardSideWrapper'>
            <Form onSubmit={onSubmit}/>
        </div>
    )
}
