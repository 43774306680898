import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getContactRequests(search){
    return await https.get(`${api_url}admin/contact-us?${search}`);
}
export async function markAsRead(id){
    return await https.post(`${api_url}admin/contact-us/${id}/toggle-read`);
}
export async function removeContactus(id){
    return await https.post(`${api_url}admin/contact-us/${id}/destroy`);
}
export async function bulkAction(data){
    return await https.post(`${api_url}admin/contact-us/pluck-action`,data);
}