import { useState , useEffect , React} from "react";
import { Outlet } from "react-router-dom";
import { getAdminProfileInfo } from "./Apis/profile.js";
import AuthLayout from './Layouts/authLayout/authLayout.jsx';


export default function ProtectedRoutes() {
    let [authorized , setauthorized] = useState('');
    useEffect(() =>{
        getAdminProfileInfo().then(() => {
            setauthorized('authorized')
        }).catch(()=>(setauthorized('not-authorized')))
    } , []);

    if(authorized === 'authorized'){
        return <Outlet/> 
    }else if(authorized === 'not-authorized'){
        return <AuthLayout/>;
    }else{
        return <>
        </>
    }
    
}