import axios from "axios";
/* import i18n from './../i18n'; */
const AdminToken = `Bearer ` + localStorage.getItem('Tourism_Admin_Token');

export let https = axios.create({
    headers : {
        Authorization: AdminToken,
        AccessControlAllowOrigin: '*',
        lang: localStorage.getItem('i18nextLng')
    }
})