import React, { useContext, useEffect, useRef, useState } from 'react';
import search from "../../../assets/icons/search.svg";
import delete_icon from "../../../assets/icons/delete.svg";
import arrow from "../../../assets/images/ExpandArrow.png";
import { useLocation } from 'react-router';
import moment from 'moment';
import { bulkAction, getContactRequests, markAsRead, removeContactus } from '../../../Apis/contactusApi';
// import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { BsEnvelope, BsEnvelopeOpen } from "react-icons/bs";
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import Pagination from '../../../Components/Pagination/Pagination';
import { useReactToPrint } from 'react-to-print';
import { useSearchParams } from 'react-router-dom';
import permissionsContext from '../../../Contexts/permissionsContext';
import TitleContext from '../../../Contexts/TitleContext';
// import UnReadLeadsContext from '../../../Contexts/notificationContext';
import { t } from 'i18next';
import './contact.scss';
import { getStatistics } from '../../../Apis/overview';

export default function ContactRequests() {
    /* let { changeUnReadContactUs, unReadContactUs } = useContext(UnReadLeadsContext); */
    let { Permissions } = useContext(permissionsContext);

    /* ***************************************** */
    const componentRef = useRef();
    let cloneRef = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {
            cloneRef = componentRef.current.cloneNode(true);
            cloneRef.firstChild.firstChild.firstChild.classList.add('d-none');
            cloneRef.firstChild.firstChild.lastChild.classList.add('d-none');
            for (let i = 0; i < cloneRef.lastChild.childNodes.length; i++) {
                if (i % 2 === 0) {
                    cloneRef.lastChild.childNodes[i].firstChild.classList.add('d-none');
                    cloneRef.lastChild.childNodes[i].lastChild.classList.add('d-none');
                }
            }
        },
        content: () => cloneRef,
    });
    /* ***************************************** */
    let { changeTitle } = useContext(TitleContext);
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    let [allContacts, setAllContacts] = useState([]);
    let [allContactsNumber, setallContactsNumber] = useState("");
    let [newContactsNumber, setNewContactsNumber] = useState("");
    let [oldContactsNumber, setOldContactsNumber] = useState("");
    let [contactId, setContactId] = useState("");
    let [validateDelete, setValidateDelete] = useState("");
    let [checkedInputs, setCheckedInputs] = useState([]);
    let [fireModalToDeleteBulk, setFireModalToDeleteBulk] = useState(false);
    let closeDeleteContactModalRef = useRef();
    let closeDeleteBulkContactModalRef = useRef();
    let deleteModalRef = useRef();
    let deleteModalBulkRef = useRef();
    const [pageCount, setPagecount] = useState(0);
    const [filterObj, setfilterObj] = useState({ search: '', per_page: '10', page: '1', date_from: '', date_to: '',read:'' });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])
    const [searchParams] = useSearchParams();
    const [params] = useState(searchParams.get("status"));
    const unreadContact = useRef();

    // onchange multiple checkboxs
    const toggleCheckbox = (e, item) => {
        let arr = [...checkedInputs];
        if (e.target.checked) {
            arr.push(item.id);
            setCheckedInputs(arr);
            setFireModalToDeleteBulk(true);
        } else {
            let x = arr.indexOf(item.id);
            if (x > -1) {
                arr = arr.filter((i) => i !== item.id);
                setCheckedInputs(arr);
            }
            if (arr && arr.length === 0) {
                setFireModalToDeleteBulk(false);
            }
        }
    };

    // onchange select all rows
    const toggleSelectAllCheck = (e) => {
        let mycheckedInputs = [...checkedInputs];
        if (e.target.checked) {
            allContacts.map((c) => {
                mycheckedInputs.push(c.id);
                return true
            });
            setFireModalToDeleteBulk(true);
        } else {
            mycheckedInputs.length = 0;
            setFireModalToDeleteBulk(false);
        }
        setCheckedInputs(mycheckedInputs);
    }

    function isActionDisabled() {
        if (checkedInputs && checkedInputs.length) {
            setFireModalToDeleteBulk(true)
        }
    }

    // get all contacts requests
    function getAllContactsRequests(status='') {
        let filter = { ...filterObj };
        filter.read = status;
        setfilterObj(filter) ;
        let obj = `search=${filter && filter.search}&per_page=${filter && filter.per_page}&page=${filter && filter.page}&date_from=${filter && filter.date_from}&date_to=${filter && filter.date_to}&read=${status}`;
        let myallContacts = [...allContacts];
        getContactRequests(obj).then(res => {
            myallContacts = res.data.data;
            let total = res.data.pagination.total;
            setPagecount(Math.ceil(total / filterObj.per_page));
            setAllContacts(myallContacts);
            getStatistics().then((res) => {
                let over = res.data.data;
                setallContactsNumber(over.contact_us_number);
                setNewContactsNumber(over.unread_contact_us_number);
                setOldContactsNumber(over.read_contact_us_number);
            });
        }).catch(err =>
            console.log(err.response))
    }

    useEffect(() => {
        changeTitle(t('contact-requests'));
        getAllContactsRequests();
        if (params) {
            unreadContact.current.click();
        }
        // eslint-disable-next-line
    }, []);

    // validate before delete contact request
    function deleteValidation(e) {
        validateDelete = e.target.value;
        setValidateDelete(validateDelete);
        if (validateDelete === "DELETE") {
            deleteModalRef.current.classList.add("d-none");
            deleteModalBulkRef.current.classList.add("d-none");
        }
    }

    // confirm delete single or multiple contact request
    function confirmDelete(e, type) {
        e.preventDefault();
        if (validateDelete === "DELETE") {
            // send request
            if (contactId || (checkedInputs && checkedInputs.length)) {
                setLoading(true);
                if (type === 1) {
                    removeContactus(contactId).then(res => {
                        setLoading(false);
                        document.getElementById('deleteContactForm').reset();
                        getAllContactsRequests();
                        closeDeleteContactModalRef.current.click();
                        getStatistics().then((res) => {
                            let over = res.data.data;
                            setallContactsNumber(over.contact_us_number);
                            setNewContactsNumber(over.unread_contact_us_number);
                            setOldContactsNumber(over.read_contact_us_number);
                        });
                    }).catch(err => {
                        setLoading(false);
                    })
                } else {
                    bulkAction({ contact_us: checkedInputs, action: 'destroy' }).then(res => {
                        setLoading(false);
                        document.getElementById('deleteContactBulkForm').reset();
                        getAllContactsRequests();
                        closeDeleteBulkContactModalRef.current.click();
                        getStatistics().then((res) => {
                            let over = res.data.data;
                            setallContactsNumber(over.contact_us_number);
                            setNewContactsNumber(over.unread_contact_us_number);
                            setOldContactsNumber(over.read_contact_us_number);
                        });
                    }).catch(err => {
                        setLoading(false);
                    })
                }
            }
        } else {
            deleteModalRef.current.classList.remove("d-none");
            deleteModalBulkRef.current.classList.remove("d-none");
        }
    }
    
    function bulkReadHandler(act) {
        if (checkedInputs && checkedInputs.length) {
            bulkAction({ contact_us: checkedInputs, action: act }).then(() => {
                getAllContactsRequests(filterObj.read)
                getStatistics().then((res) => {
                    let over = res.data.data;
                    setallContactsNumber(over.contact_us_number);
                    setNewContactsNumber(over.unread_contact_us_number);
                    setOldContactsNumber(over.read_contact_us_number);
                });
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    function resetInput() {
        document.getElementById('deleteContactForm').reset();
        document.getElementById('deleteContactBulkForm').reset();
        deleteModalRef.current.classList.add("d-none");
        deleteModalBulkRef.current.classList.add("d-none");
        setValidateDelete("");
    }

    // mark contact message as read
    function markContactMsgRead(id, status) {
        let myallContacts = [...allContacts];
        if (status === 'unread') {
            markAsRead(id).then((res) => {
                myallContacts.map((msg) => {
                    if (msg.id === id) {
                        msg.read = 'read';
                    }
                    return true
                })
                setAllContacts(myallContacts)
                getStatistics().then((res) => {
                    let over = res.data.data;
                    setallContactsNumber(over.contact_us_number);
                    setNewContactsNumber(over.unread_contact_us_number);
                    setOldContactsNumber(over.read_contact_us_number);
                });
            })
        }
    }

    // get readMsgs
    function getReadMsgs() {
        let filter = { ...filterObj };
        filter.read = 'read';
        filter.page = 1;
        setfilterObj(filter);
        let obj = `search=${filter && filter.search}&per_page=${filter && filter.per_page}
        &page=1&read=${filter && filter.read}`;
        // let obj=`read=read`
        getContactRequests(obj).then(res => {
            let thisallContacts = [...allContacts];
            thisallContacts = res.data.data;
            let total = res.data.pagination.total;
            setPagecount(Math.ceil(total/filter.per_page));
            setAllContacts(thisallContacts);
        })
    }

    // get unreadMsgs
    function getUneadMsgs() {
        let filter = { ...filterObj };
        filter.read = 'unread';
        filter.page = 1;
        setfilterObj(filter);
        let obj = `search=${filter && filter.search}&per_page=${filter && filter.per_page}
        &page=1&read=${filter && filter.read}`;
        getContactRequests(obj).then(res => {
            let thisallContacts = [...allContacts];
            thisallContacts = res.data.data;
            let total = res.data.pagination.total;
            setPagecount(Math.ceil(total/filter.per_page));
            setAllContacts(thisallContacts);
        })
    }

    // filters on contact us
    function changeFilterObj(e) {
        let thisObj = { ...filterObj }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj(thisObj);
    }

    function getFilteredNews() {
        let filter = { ...filterObj };
        getAllContactsRequests(filter.read)
    }

    function getDateValue(data) {
        let thisObj = { ...filterObj }
        if (data !== null) {
            thisObj.date_from = moment(data[0]).format('YYYY-MM-DD');
            thisObj.date_to = moment(data[1]).format('YYYY-MM-DD');
        } else {
            thisObj.date_from = '';
            thisObj.date_to = '';
        }
        setfilterObj(thisObj);
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        let obj = `search=${filter && filter.search}&per_page=${filter && filter.per_page}&page=${data.selected + 1}&date_to=${filter && filter.date_to}&date_from=${filter && filter.date_from}&read=${filter.read}`;
        getContactRequests(obj).then(
            res => {
                setAllContacts(res.data.data);
            }
        )
    }

    return (
        <>
            <section className='contact-requests componentGlobalWrapper d-flex flex-column justify-content-between pb-0 mb-4'>
                <div>
                    <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                        <div className='tablinks active' id="Gallery-tab" data-bs-toggle="tab" onClick={() => getAllContactsRequests('')}><span>{t('all-requests')}</span> <span className='tabcounter'>({allContactsNumber})</span></div>
                        <span className='separator'></span>
                        <div ref={unreadContact} className='tablinks' id="published-tab" data-bs-toggle="tab" onClick={getUneadMsgs}> <span>{t('unread-requests')}</span> <span className='tabcounter'>({newContactsNumber})</span></div>
                        <span className='separator'></span>
                        <div className='tablinks' id="trashed-tab" data-bs-toggle="tab" onClick={getReadMsgs}><span>{t('read-requests')}</span> <span className='tabcounter'>({oldContactsNumber})</span></div>
                    </div>

                    <div className='searchActionsBar w-100 justify-content-between flex-wrap'>
                        <div className="d-flex align-items-center flex-wrap">
                            
                            {/*** bulk actions ***/}
                            {Permissions?.contact_us && Permissions?.contact_us['pluck-action'] &&<div>
                                <button className='position-relative languageDropdown bulkDropdown' data-bs-toggle="dropdown">
                                    <span>bulk actions</span>
                                    <img src={arrow} alt="arrow" />
                                </button>
                                <ul className="dropdown-menu custom-bulk-dropdown pt-0" aria-labelledby="dropdownMenuButton2">
                                    {/* {/* {Permissions && Permissions.contact && Permissions.contact.delete &&  */}
                                     <li className={fireModalToDeleteBulk ? `` : 'notSelected'}>
                                        <span className={`dropdownItem px-2 py-1`} {...(fireModalToDeleteBulk ? { 'data-bs-target': '#deleteBulkContactModal', 'data-bs-toggle': "modal" } : {})} onClick={isActionDisabled}>
                                            delete selected
                                        </span>
                                    </li>
                                    {/* } */}
                                    <li className={fireModalToDeleteBulk ? `` : 'notSelected'}>
                                        <span className={`dropdownItem px-2 py-1`} onClick={() => bulkReadHandler('read')}>
                                            make read
                                        </span>
                                    </li>
                                    <li className={fireModalToDeleteBulk ? `` : 'notSelected'}>
                                        <span className={`dropdownItem px-2 py-1`} onClick={() => bulkReadHandler('unread')}>
                                            make unread
                                        </span>
                                    </li>

                                    {/* {Permissions && Permissions.blocked_ip && Permissions.blocked_ip.create && 
                                <li className={fireModalToBlockBulk ? `` : 'notSelected'}>
                                    <span className="dropdownItem px-2 py-1" {...(fireModalToBlockBulk ? {'data-bs-target': '#toggleblockIpModal' , 'data-bs-toggle': "modal"} : {})} onClick={()=> toggleBlock(true , 0)}>
                                        block selected
                                    </span>
                                </li>} */}

                                    {/* {Permissions && Permissions.blocked_ip && Permissions.blocked_ip.delete &&<li className={fireModalToUnblockBulk ? `` : 'notSelected'}>
                                    <span className="dropdownItem px-2 py-1" {...(fireModalToUnblockBulk ? {'data-bs-target': '#toggleblockIpModal' , 'data-bs-toggle': "modal"} : {})} onClick={()=> toggleBlock(false , 0)}>
                                        unblock selected
                                    </span>
                                </li>} */}
                                </ul>
                            </div>}
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' name='search' onChange={changeFilterObj} className='barSearchInput' />
                                    {/* {/*  <span className="inputPlaceholder">search</span>  */}
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                            <div className='dataRange'>
                                <DateRangePicker showOneCalendar={true} appearance="default" placeholder="from : to" onChange={(value) => getDateValue(value)} />
                            </div>
                            {/* show select */}
                            <div className='perPageContainer'>
                                <label htmlFor='selectShow' className='perPageLabel'>
                                    {t('show')}
                                </label>
                                <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' defaultValue='10'>
                                    <option value='5'>5</option>
                                    <option value='10'>10</option>
                                    <option value='15'>15</option>
                                    <option value='20'>20</option>
                                </select>
                            </div>
                            {/*** apply search btn  ***/}
                            <button className='applySearchBtn' onClick={getFilteredNews}>{t("apply")}</button>
                        </div>
                        {/*** export as pdf ***/}
                        <div className="dropdown">
                            <button className="dropdown-toggle exportDropdown confirmBtn" type="button" id="exportButton" data-bs-toggle="dropdown" aria-expanded="false">
                                {t('export')}
                            </button>
                            <ul className="dropdown-menu custom-bulk-dropdown" aria-labelledby="exportButton">
                                {/* <PDFDownloadLink document={<PDFFile />} fileName="lead.pdf">
                                <li>pdf</li>
                            </PDFDownloadLink> */}
                                <li className='notSelected' onClick={handlePrint}>
                                    <span className=' px-2 py-1'>pdf</span>
                                </li>
                                {/* <li>xml</li> */}
                            </ul>
                        </div>
                    </div>

                    <div className='tableWrapper'>
                        <table ref={componentRef} className="w-100 table" >
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox" onChange={toggleSelectAllCheck} />
                                    </th>
                                    <th>{t('request-details')}</th>
                                    <th>{t('message')}</th>
                                    <th>{t("date")}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allContacts && allContacts.map((contact, index) => (
                                    <React.Fragment key={index}>
                                        <tr className='stripedRow'>
                                            <td className='pe-0 hideAtPrint' style={{ 'maxWidth': '2rem' }}>
                                                <input type="checkbox" checked={checkedInputs.find((p) => p === contact.id) ? true : false} onChange={(e) => toggleCheckbox(e, contact)} />
                                            </td>
                                            <td>
                                                <div className='row contactPersonal m-0 p-0 mb-2'>
                                                    <div className='col-4 ps-0 contactDetails'>
                                                        {t('name')}
                                                    </div>
                                                    <div className='col-6 ps-0'>
                                                        {contact.name}
                                                    </div>
                                                </div>
                                                <div className='row contactPersonal m-0 p-0 mb-2'>
                                                    <div className='col-4 ps-0 contactDetails'>
                                                        {t('phone')}
                                                    </div>
                                                    <div className='col-6 ps-0'>
                                                        {contact.phone}
                                                    </div>
                                                </div>
                                                <div className='row contactPersonal m-0 p-0 mb-2'>
                                                    <div className='col-4 ps-0 contactDetails'>
                                                        {t('email')}
                                                    </div>
                                                    <div className='col-6 ps-0'>
                                                        {contact.email}
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ "width": "22%" }}>
                                                {`${contact.message.substring(0, 25)}...`}
                                            </td>
                                            <td>
                                                <div className='d-flex'>
                                                    {moment(contact.created_at).format('DD-MM-YY h:mm A')}
                                                </div>
                                            </td>
                                            <td className='hideAtPrint'>
                                                <div className='d-flex'>
                                                    {Permissions?.contact_us && Permissions.contact_us['toggle-read'] && <div className='contactActions' data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls="collapseExample1" onClick={() => markContactMsgRead(contact.id, contact.read)}>
                                                        <span className='envelopWrapper'>
                                                            {contact.read === 'read' ? <BsEnvelopeOpen /> : <BsEnvelope />}
                                                        </span>
                                                    </div>}
                                                    {Permissions?.contact_us && Permissions.contact_us['destroy'] && <div     className='contactActions'>
                                                        <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteContactModal' onClick={() => setContactId(contact.id)} />
                                                    </div>}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="collapse contactMsgRow" id={`collapse${index}`}>
                                            <td colSpan="8">{contact.message}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/**** delete single contact modal ****/}
                    <div className="modal fade" id="deleteContactModal" tabIndex={-1} aria-labelledby="deleteContactModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div ref={closeDeleteContactModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={resetInput}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form id="deleteContactForm" onSubmit={(e) => confirmDelete(e, 1)}>
                                        <div className='formInputsWrapper'>
                                            <p>{t('do-you-want-to-delete-this')} {t('message')} {t('?')}</p>
                                            <div className='mb-4'>
                                                <div className="form-floating">
                                                    <input autoComplete='off' onChange={deleteValidation} className='w-100 form-control' name="validate" id='floatingInput' type='text' placeholder='delete' required />
                                                    <label htmlFor="floatingInput">{t('please-type')} <span>"DELETE"</span> {t('to-confirm')}</label>
                                                </div>
                                                <div ref={deleteModalRef} className='validateToDelete d-none text-danger mt-1'>
                                                {t('please-type-"DELETE"-in-capital-letters')}.
                                                </div>
                                            </div>
                                            {/*** modal footer ***/}
                                            <div className='d-flex justify-content-end align-items-end'>
                                                <div className='cancelBtn' onClick={resetInput} data-bs-dismiss="modal">{t('cancel')}</div>
                                                {!loading ?
                                                    <button className={validateDelete === "DELETE" ? `deleteBtn` : `confirmBtn`} type='submit'>{t('delete')}</button>
                                                    :
                                                    <button className='confirmBtn deleteBtn' disabled>
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                        {t('deleting')}...
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/**** delete multiple contact modal ****/}
                    <div className="modal fade" id="deleteBulkContactModal" tabIndex={-1} aria-labelledby="deleteBulkContactModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div ref={closeDeleteBulkContactModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={resetInput}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form id="deleteContactBulkForm" onSubmit={(e) => confirmDelete(e, 0)}>
                                        <div className='formInputsWrapper'>
                                            <p>{t('do-you-want-to-delete-this')} {t('message')} {t('?')}</p>
                                            <div className='mb-4'>
                                                <div className="form-floating">
                                                    <input autoComplete='off' autoSave='off' onChange={deleteValidation} className='w-100 form-control' name="validate" id='floatingInput' type='text' placeholder='delete' required />
                                                    <label htmlFor="floatingInput">{t('please-type')} <span>"DELETE"</span> {t('to-confirm')}</label>
                                                </div>
                                                <div ref={deleteModalBulkRef} className='validateToDelete d-none text-danger mt-1'>
                                                {t('please-type-"DELETE"-in-capital-letters')}.
                                                </div>
                                            </div>
                                            {/*** modal footer ***/}
                                            <div className='d-flex justify-content-end align-items-end'>
                                                <div className='cancelBtn' onClick={resetInput} data-bs-dismiss="modal">{t('cancel')}</div>
                                                {!loading ?
                                                    <button className={validateDelete === "DELETE" ? `deleteBtn` : `confirmBtn`} type='submit'>{t('delete')}</button>
                                                    :
                                                    <button className='confirmBtn deleteBtn' disabled>
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                        {t('deleting')}...
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/**** toggle block modal ****/}
                    {/* <div className="modal fade" id="toggleblockIpModal" tabIndex={-1} aria-labelledby="toggleblockIpModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div ref={closeBlockIpModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={faXmark}/>
                                </div>
                            </div>
                            <div className="modal-body">
                                <form id="blockBulkIpsForm" onSubmit={confirmToggleBlockIps}>
                                    <div className='formInputsWrapper'>
                                        <p className='mb-4'>{`Do you want to ${modalObj.action === true ? `block` : `unblock`} ${modalObj.type === 1 ? `selected item ?` : `selected items ?`}`}</p>
                                        // {/*** modal footer 
                                        <div className='d-flex justify-content-end'>
                                            <div className='cancelBtn' data-bs-dismiss="modal">cancel</div>
                                            {!loading ? 
                                                <button className='deleteBtn' type='submit'>{`${modalObj.action === true ? `block` : `unblock`}`}</button>
                                                :
                                                <button className='deleteBtn' disabled>
                                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    saving...
                                                </button>
                                            }                                            
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
                <div className='d-flex justify-content-end mt-auto'>
                    <div className=''>
                        <Pagination pagesNumber={pageCount} pageChangeHandler={handlepageChange} />
                    </div>
                </div>
            </section>
        </>
    )
}
