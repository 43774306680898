import React, { useContext } from 'react'
import { Route } from 'react-router';
import Overview from './../Pages/Overview/overview';
import { Routes } from 'react-router-dom';
import EditAccountSettings from './../Pages/Account/Edit';
import EditSecuritySettings from './../Pages/Security/Edit';
import AdminsList from './../Pages/Admins/AdminsList';
import AddAdmin from '../Pages/Admins/AdminsForm/Add';
import EditAdmin from './../Pages/Admins/AdminsForm/Edit';
import Roles from './../Pages/Roles/Roles';
import EditGeneralSettings from './../Pages/General Settings/GeneralSettingsForm';
import WebsitePages from './../Pages/Website pages/Allpages/WebsitePages';
import HomeImage from './../Pages/Website pages/Home page/HomeImage';
import TermsAndConditions from './../Pages/Website pages/Terms and conditions/TermsAndConditions';
import PrivacyPolicy from './../Pages/Website pages/Privacy policy/PrivacyPolicy';
import ArticlesList from './../Pages/Articles/ArticlesList';
import AddArticle from './../Pages/Articles/ArticleForm/Add';
import EditArticle from '../Pages/Articles/ArticleForm/Edit';
import SectionsList from '../Pages/Sections/MainSections';
import SubSections from './../Pages/Sections/SubSections';
import AddSection from './../Pages/Sections/SectionForm/Add';
import EditSection from './../Pages/Sections/SectionForm/Edit';
import PermissionsContext from '../Contexts/permissionsContext';
import ContactRequests from './../Pages/Website pages/contact us/contactUS';
import Scripts from './../Pages/Scripts/Scripts';
import Contactus from './../Pages/Website pages/contact us/contactusPage';

export default function AllRoutes() {
    let {Permissions} = useContext(PermissionsContext);

    return (
        <>
            { Permissions &&
                <Routes>
                    <Route path="overview" element={<Overview />} />
                    {/* admins and roles */}
                    {(Permissions?.admins || Permissions?.roles) && <Route path='admins/' >
                        {Permissions?.admins?.show && <Route path="admins-list" element={<AdminsList />} />}
                        {Permissions?.admins?.create &&<Route path="create" element={<AddAdmin />} />}
                        {Permissions?.admins?.update && <Route path=":id/update" element={<EditAdmin />} />}
                        {Permissions?.roles?.show && <Route path="roles/*" element={<Roles />} />}
                    </Route>}
                    {/* settings */} 
                    {(Permissions?.settings?.show || Permissions?.scripts?.show) && <Route path='settings/' >
                        <Route path='account' element={<EditAccountSettings />} />
                        <Route path='security' element={<EditSecuritySettings />} />
                        <Route path='general' element={<EditGeneralSettings />} />
                        <Route path='scripts' element={<Scripts />} />
                    </Route>}
                    {/**** appearance *****/}
                    {(Permissions?.settings?.show || Permissions?.settings?.update ) && <Route path='appearance/' >
                        <Route path='pages'>
                            <Route path='' element={<WebsitePages />} />
                            <Route path='home' element={<HomeImage />} />
                            <Route path='terms-and-conditions' element={<TermsAndConditions />} />
                            <Route path='privacy-policy' element={<PrivacyPolicy />} />
                            <Route path='contact-us' element={<Contactus />} />
                        </Route>
                    </Route>}
                    {/* ****Articles**** */}
                    {Permissions?.articles && <Route path='articles/' >
                        {(Permissions?.articles?.show || Permissions?.articles?.show_his_own_subjects) && <Route path='list' element={<ArticlesList />} />}
                        {Permissions?.articles?.create && <Route path='create' element={<AddArticle />} />}
                        {Permissions?.articles?.update && <Route path=":id/update" element={<EditArticle />} />}
                    </Route>}
                    {/* ****Sections**** */}
                    {Permissions?.sections && <Route path='sections/' >
                        {Permissions?.sections?.show && <Route path='main' element={<SectionsList />} />}
                        {Permissions?.sections?.show && <Route path=':id/sub-sections' element={<SubSections/>} />}
                        {Permissions?.sections?.create && <Route path='create' element={<AddSection />} />}
                        {Permissions?.sections?.update && <Route path=":id/update" element={<EditSection />} />}
                    </Route>}
                    <Route path='contact-us' element={<ContactRequests/>}/>
                </Routes>
            }
        </>
    )
}
