import React, { useContext, useRef } from 'react'
import { NavLink } from 'react-router-dom';
import { basename } from '../../Configuration/config';
import overview from '../../assets/icons/overview.svg';
import leads from '../../assets/icons/Leads.svg';
import blogs from '../../assets/icons/blogs.svg';
import sections from '../../assets/icons/sections.svg';
import appearance from '../../assets/icons/appearance.svg';
import users from '../../assets/icons/users.svg';
import settings from '../../assets/icons/settings.svg';
import './Aside.scss';
import { t } from 'i18next';
import PermissionsContext from '../../Contexts/permissionsContext';

export default function Aside() {
    const { Permissions } = useContext(PermissionsContext);
    let closeRef =useRef();
    function closeOffCanvas(){
         if(closeRef.current.style.display === 'none'){
            closeRef.current.click()
        }
    }
    return (
        <>
            {Permissions && 
            <div className='asideWrapper' >
                <div className='d-flex '>
                    <button ref={closeRef} type="button" className="btn-close text-reset sideBarClose px-4 d-lg-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <ul className='asideList'>
                    <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/overview`} onClick={closeOffCanvas}>
                            <img src={overview} alt="icon" />
                            <span className='capitalWord'>{t('overview')}</span>
                        </NavLink>
                    </li>
                </ul>
                {Permissions?.contact_us?.show && <ul className='asideList'>
                    <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/contact-us`}  onClick={closeOffCanvas}>
                            <img src={leads} alt="icon" />
                            <span className='capitalWord'>{t('contact-requests')}</span>
                        </NavLink>
                    </li>
                </ul>}
                {/* <li>
                            <NavLink className={`navLink`} to={`${basename}/dashboard/leads`} data-bs-toggle="collapse" data-bs-target="#leadsCollapse">
                                <img src={leads} alt="icon" />
                                <span className='mx-3'>Leads</span>
                            </NavLink>
                            <ul className="collapse asideDropdown" id='leadsCollapse'>
                                <li>
                                    <NavLink to={`${basename}/dashboard/leads/leads-requests`} className="nestedNavLink">leads requests </NavLink>
                                </li><li>
                                    <NavLink to={`${basename}/dashboard/leads/contact-requests`} className="nestedNavLink">
                                        contact requests
                                    </NavLink>
                                </li>
                            </ul>
                        </li> */
                }

                 <div className='asideSplitter'></div> 
                {(Permissions?.sections?.show || Permissions?.sections?.create) && <ul className='asideList'>
                    <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/sections`} data-bs-toggle="collapse" data-bs-target="#sectionsCollapse">
                            <img src={sections} alt="icon" />
                            <span className='capitalWord'>{t('sections')}</span>
                        </NavLink>
                        <ul className="collapse asideDropdown" id='sectionsCollapse'>
                            {Permissions.sections?.create && <li>
                                <NavLink to={`${basename}/dashboard/sections/create`} className="nestedNavLink"  onClick={closeOffCanvas}>{t('add-section')}</NavLink>
                            </li>}
                            {Permissions.sections?.show && <li>
                                <NavLink to={`${basename}/dashboard/sections/main`} className="nestedNavLink"  onClick={closeOffCanvas}>{t('sections-list')}</NavLink>
                            </li>}
                        </ul>
                    </li>
                </ul>}
                {(Permissions.articles?.show || Permissions.articles?.create || Permissions?.articles?.show_his_own_subjects) && <ul className='asideList'>
                    <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/articles`} data-bs-toggle="collapse" data-bs-target="#blogsCollapse">
                            <img src={blogs} alt="icon" />
                            <span className='capitalWord'>{t('articles')}</span>
                        </NavLink>
                        <ul className="collapse asideDropdown" id='blogsCollapse'>
                            {Permissions.articles?.create && <li>
                                <NavLink to={`${basename}/dashboard/articles/create`} className="nestedNavLink"  onClick={closeOffCanvas}>{t('add-article')}</NavLink>
                            </li>}
                            {(Permissions.articles?.show || Permissions?.articles?.show_his_own_subjects) && <li>
                                <NavLink to={`${basename}/dashboard/articles/list`} className="nestedNavLink"  onClick={closeOffCanvas}>{t('articles-list')}</NavLink>
                            </li>}
                        </ul>
                    </li>
                </ul>}
                <div className='asideSplitter' ></div>
                <ul className='asideList'>
                    {/* pages */}
                    {(Permissions.settings?.show ) && <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/appearance`} data-bs-toggle="collapse" data-bs-target="#appearanceCollapse">
                            <img src={appearance} alt="icon" />
                            <span className='capitalWord' >{t('appearance')}</span>
                        </NavLink>
                        <ul className="collapse asideDropdown" id='appearanceCollapse'>
                            <li>
                                <NavLink to={`${basename}/dashboard/appearance/pages`} className="nestedNavLink">{t('pages')}</NavLink>
                            </li>
                        </ul>
                    </li>}

                    {/* admins and roles */}
                    {( Permissions.admins?.create || Permissions.roles?.show || Permissions.roles?.create) && <li>
                        <NavLink className={`navLink`} data-bs-toggle="collapse" data-bs-target="#usersCollapse" to={`${basename}/dashboard/admins`}>
                            <img src={users} alt="icon" />
                            <span className='capitalWord' >{t('admins')}</span>
                        </NavLink>
                        <ul className="collapse asideDropdown" id='usersCollapse'>
                            {Permissions.admins?.create && <li>
                                <NavLink to={`${basename}/dashboard/admins/create` } className="nestedNavLink"  onClick={closeOffCanvas}>{t('add-admin')}</NavLink>
                            </li>}
                            {Permissions.roles?.show && <li>
                                <NavLink to={`${basename}/dashboard/admins/admins-list`} className="nestedNavLink"  onClick={closeOffCanvas}>{t('all-admins')}</NavLink>
                            </li>}
                            {Permissions.roles?.show && <li>
                                <NavLink to={`${basename}/dashboard/admins/roles/add`} className="nestedNavLink"  onClick={closeOffCanvas}>{t('roles')}</NavLink>
                            </li>}
                        </ul>
                    </li>}

                    {/* settings */}
                    {Permissions.settings?.show && <li>
                        <NavLink className={`navLink`} data-bs-toggle="collapse" data-bs-target="#settingsCollapse" to={`${basename}/dashboard/settings`}>
                            <img src={settings} alt="icon" />
                            <span className='capitalWord'>{t('settings')}</span>
                        </NavLink>
                        <ul className="collapse asideDropdown" id='settingsCollapse'>
                            <li>
                                <NavLink to={`${basename}/dashboard/settings/general`} className="nestedNavLink" onClick={closeOffCanvas} >{t('general')}</NavLink>
                            </li>
                            <li>
                                <NavLink to={`${basename}/dashboard/settings/security`} className="nestedNavLink" onClick={closeOffCanvas}>{t('security')}</NavLink>
                            </li>
                            <li>
                                <NavLink to={`${basename}/dashboard/settings/account`} className="nestedNavLink" onClick={closeOffCanvas}>{t('account')}</NavLink>
                            </li>
                            <li>
                                <NavLink to={`${basename}/dashboard/settings/scripts`} className="nestedNavLink lastOne" onClick={closeOffCanvas}>{t('script-page')}</NavLink>
                            </li>
                        </ul>
                    </li>}
                </ul>
            </div>}
        </>
    )
}
