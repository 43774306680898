import React, { useContext, useEffect,  useState} from 'react';
import { useLocation } from 'react-router';
import formModel from './formModel';
import { setAdminProfileData } from './formHandler';
import { getAdminProfileInfo } from  '../../Apis/profile';
import TitleContext from './../../Contexts/TitleContext';
import '../forms.scss'
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export default function Form(props) {
    const { pathname } = useLocation();
    let {changeTitle} = useContext(TitleContext);
    const {i18n} = useTranslation(); 
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(()=>{
        changeTitle(t(`account-settings`));
        getAdminProfileInfo().then((res) => {
            let x = res.data.data;
            delete x.role;
            setModel({...x});
        })
        // eslint-disable-next-line
    } , [i18n.language]);

    // onchange
    const setFormData = (e, property) => {
        setAdminProfileData(model, setModel, e, property);
    }

    // onsubmit
    const confirmAdminProfileInfo = async (e , model)=>{
        e.preventDefault();
        let formData = new FormData();
        for(const key in model){
            formData.append(key, model[key] ? model[key] : '');
        }
        setLoading(true);
        // send request
        let submitProps = await props.onSubmit(e, formData);

        if(submitProps === "done"){
            setLoading(false);
        }else{
            setLoading(false);
            setErrors(submitProps);
        }
        
    }


    return (
        <>
        <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{t('profile')}</h5>
            </div>

            <form id='adminForm' onSubmit={(e) => confirmAdminProfileInfo(e, model)}>
                <div className='d-flex justify-content-start'>
                    <div className='pagesForm'>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='firstname' className='formLabel'>{t(`name`)}</label>
                            <input maxLength='255' minLength='3' name="name" id='firstname' type='text' value={( model?.name) ? model.name : ""} onChange={(e) => setFormData(e, 'name')} required pattern="^(.*)[^ ](.*)$" />
                        </div>
                        
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='username' className='formLabel'>{t(`username`)}</label>
                            <input maxLength='255' name="username" id='username' type='text' value={(model?.username) ? model.username : ""} onChange={(e) => setFormData(e, 'username')} required pattern="^(.*)[^ ](.*)$" />
                            {errors?.username && 
                                <div className='text-danger validateToDelete'>{errors?.username}</div>
                            }
                        </div>
                        <div className='d-flex flex-column mb-5'>
                            <label htmlFor='email' className='formLabel'>{t('email')}</label>
                            <input maxLength='255' name="email" id='email' type='email' value={(model?.email) ? model.email : ""} onChange={(e) => setFormData(e, 'email')} required pattern="^(.*)[^ ](.*)$" />
                            {errors?.email && <div className='text-danger validateToDelete'>
                                {errors?.email}
                            </div>}
                        </div>
                    </div>
                </div>
                
                {!loading ? 
                    <button className='confirmBtn' type='submit'>{t(`save-changes`)}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {t('saving')}...
                    </button>
                }
            </form>
        </section>
        </>
    )
}
