let obj = {
    locales: [],
    facebook:'',
    twitter: '',
    snapchat:'',
    instagram: '',
    youtube: '',
    whatsapp: '',
    section:'',
    latitude: '',
    longitude: '',
    address_link:'',
    status: 'inactive',
    images: [],
    old_images:[]
}
export default obj;