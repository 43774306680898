import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useState } from 'react';
import { getScripts, toggleActiveScript, updateScript } from '../../Apis/scripts.js';
import { t } from 'i18next';
import TitleContext from '../../Contexts/TitleContext.js';
import './scripts.css';
export default function Scripts() {
    let { changeTitle } = useContext(TitleContext);
    const [scripts, setScripts] = useState([]);
    const [loaders, setLoaders] = useState([]);
    const [activateMessage, setactivateMessage] = useState('');

    useEffect(() => {
        changeTitle(t(`script-page`));
        getAllScripts()
        // eslint-disable-next-line
    }, []);

    function getAllScripts() {
        let thisLoaders = [...loaders];
        getScripts().then((res) => {
            setScripts(res.data.data);
            res.data.data.map(() => {
                thisLoaders.push(false);
                return true;
            });
            setLoaders(thisLoaders);
        })
    }

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function updateHandler(e, id) {
        let thisScripts = [...scripts]
        thisScripts.map((s) => {
            if (s.id === id) {
                let i = scripts.indexOf(s)
                thisScripts[i][e.target.name] = e.target.value;
            }
            return true
        })
        setScripts(thisScripts);
    }

    function onSubmit(e, id, index) {
        e.preventDefault();
        let flags = [...loaders];
        flags[index] = true;
        setLoaders(flags);
        let scriptdata = scripts.filter((s) => (s.id === id))[0];
        updateScript(id, { 'script': scriptdata.script }).then((res => {
            let flags = [...loaders];
            flags[index] = false;
            setLoaders(flags);
        })).catch((err) => {
            let flags = [...loaders];
            flags[index] = false;
            setLoaders(flags);
        })
    }

    function toggleStatus(e, id) {
        let thisScripts = [...scripts];
        thisScripts.map((script) => {
            if (script.id === id) {
                let i = thisScripts.indexOf(script);
                if (e.target.checked) {
                    thisScripts[i].status = 'active';
                } else {
                    thisScripts[i].status = 'inactive';
                }
            }
            return true
        })
        setScripts(thisScripts)
        var alertTrigger = document.getElementById(`alert${id}`)
        toggleActiveScript(id).then((res) => {
            setactivateMessage(res.data.data)
            alertTrigger.classList.add('show')
            setTimeout(function () { alertTrigger.classList.remove('show') }, 1000);
        })
    }

    function removeAlert(x) {
        let alert = document.getElementById(x)
        alert.classList.remove('show')
    }

    return (
        <>
            <section className='componentGlobalWrapper p-0'>
                <div style={{ 'minHeight': '100vh' }}>
                    {scripts && scripts.map((script, i) => (
                        <div key={i}>
                            <div className='sectionTitle'>
                                
                                <div className='d-flex align-items-center'>
                                    <label className="switch mb-0">
                                        <input type="checkbox" onChange={(e) => toggleStatus(e, script.id)} name={script.id} {...(script.status === 'active' && { checked: true })} />
                                        <span className="slider round" />
                                    </label>
                                    <h5 className='formLabel mb-0'>{script.title} </h5>
                                    <div id={`alert${script.id}`} className="alert alert-success alert-dismissible fade mb-0 w-25" role="alert">
                                        <button type="button" onClick={() => removeAlert(`alert${script.id}`)} className="btn-close" ></button>
                                        <span>{activateMessage}</span>
                                    </div>
                                </div>
                            </div>
                            <form className='p-4 mt-0' onSubmit={(e) => onSubmit(e, script.id, i)}>
                                <div className='d-flex flex-column mb-4'>
                                    <label htmlFor='script' className='formLabel'>{t('script')}</label>
                                    <textarea value={script && script.script ? script.script : ''} name="script" id='script' onChange={(e) => updateHandler(e, script.id)} />
                                </div>
                                {loaders && !loaders[i] ?
                                    <button className='confirmBtn' type='submit'>{t('update')}
                                    </button>
                                    :
                                    <button className='confirmBtn' disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {t('saving')}...
                                    </button>
                                }
                            </form>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}
