import React from 'react'
import { updateRole } from '../../../Apis/roles';
import Form from './Form';
import { useParams } from 'react-router';

export default function EditRole(props) {
    let { id } = useParams();
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await updateRole(id,data).then(res => {
            props.getAll();
            return 'done_update';          
        }).catch(err =>{
            console.error(err.response);
            return err.response.data.errors;
        })
    };

    return (
        <div className='actionCardSideWrapper'>
            <Form onSubmit={onSubmit} getAll={props.getAll} />
        </div>
    )
}
