import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getMainSections(obj=''){
    return await https.get(`${api_url}admin/sections?type=main&sort=asc&order=order&${obj}`);
}

export async function getMainSectionsWithoutPermissions(obj=''){
    return await https.get(`${api_url}admin/sections/sections?type=main&sort=asc&order=order&${obj}`);
}

export async function getSubSections(id,obj='',x=''){
    console.log(`admin/sections/${id}/get-children?${obj}&sort=asc&order=order&expect=${x}`)
return await https.get(`${api_url}admin/sections/${id}/get-children?${obj}&sort=asc&order=order&expect=${x}`);
}

export async function getSectionPerants(id){
    return await https.get(`${api_url}admin/sections/${id}/parents`);
}

export async function getSection(id){
    return await https.get(`${api_url}admin/sections/${id}`);
}

export async function deleteSection(id){
    return await https.post(`${api_url}admin/sections/${id}/destroy`);
}

export async function updateSection(id,data){
    return await https.post(`${api_url}admin/sections/${id}/update`, data);
}

export async function createSection(data){
    return await https.post(`${api_url}admin/sections/create`, data);
}

export async function reorderSections(data,parentId=null){
    return await https.post(`${api_url}admin/sections/order`, {orders:data , parent_id:parentId});
}

export async function toggleActivate(id){
    return await https.post(`${api_url}admin/sections/${id}/toggle-active`);
}