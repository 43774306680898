import React ,{ useState , useEffect , useRef, useContext} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import { supportedLanguages } from '../../../utilities/SupportedLanguages';
import removeIcon from "../../../assets/icons/removeIcon.svg";
import uploadImage from "../../../assets/icons/uploadimage.svg";
import instagram from "../../../assets/icons/instagram.svg";
import facebook from "../../../assets/icons/facebook.svg";
import website from "../../../assets/icons/website.svg";
import twitter from "../../../assets/icons/twitter.svg";
import youtube from "../../../assets/icons/youtube.svg"
import whatsapp from "../../../assets/icons/whatsapp.svg";
import snapchat from "../../../assets/icons/snapchat.svg"
import arrow from "../../../assets/images/ExpandArrow.png";
import langIcon from "../../../assets/images/language.png";
import removeTag from "../../../assets/icons/removetag.svg";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState ,convertToRaw} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Tooltip from '@mui/material/Tooltip';
import { t } from 'i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import obj from './Model';
import { setArticleData } from './FormHandler';
import { textEditorValidation } from '../../../utilities/HandleTextEditorValidation';
import { getArticle, getArticlePerants } from '../../../Apis/articles';
import { getMainSectionsWithoutPermissions, getSection, getSubSections } from '../../../Apis/sections';
import { filterLanguage } from '../../../utilities/filterLang';
import i18n from 'i18next';
import { basename } from '../../../Configuration/config';
import PermissionsContext from '../../../Contexts/permissionsContext';
import '../../forms.scss';
import { uploadImages } from '../../../Apis/uploadImages';
import CropEasy from './../../../utilities/CropEasy';
import { Dialog } from '@mui/material';
import {getAdminProfileInfo} from "../../../Apis/profile";
import {hasSuperPermission} from "../../../utilities/helpers";

export default function Form(props) {
    let {Permissions} = useContext(PermissionsContext);
    const { pathname } = useLocation();
    let navigate = useNavigate()
    const [model, setModel] = useState(obj);
    const [loading, setLoading] = useState(false); 
    const errorDivRef = useRef();
    const metaKeywordsRef = useRef();
    const [keywords , setKeywords] = useState({});
    const [editors , setEditors] = useState({});
    const languageRef = useRef();
    const imgRef = useRef();
    const {id} = useParams();
    const [propImgsSrc ,setPropImgsSrc] = useState([]);
    const [sideSections , setSideSections] = useState([]);
    const [errors, setErrors]= useState({});
    const [parents ,setParents] = useState([]);
    const [admin, setAdmin] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        getMainSectionsWithoutPermissions().then((res)=>{
            let sub = [...sideSections];
            sub.push({sections:res.data.data ,id: Date.now()});
            setSideSections(sub,console.log(sub));
        })
        getAdminProfileInfo().then((res) => {
            setAdmin(res.data.data);
        });
    }, []);

    useEffect(() => {
        if(id){
            getArticle(id).then((res)=>{
                let x = {...res.data.data,old_images:[]};
                let oldImages = [...propImgsSrc];
                x.images.map((img)=>{
                    let file = img.url;
                    let obj = {file:file ,src:file ,id:img.id}
                    oldImages.push(obj);
                    x.old_images.push(obj);
                    return true
                });
                setPropImgsSrc(oldImages);
                if(x.status === 'active' && Permissions?.articles['toggle-active']){
                    document.getElementById('statusCheck').checked = true;
                }
                let thisEditors = {...editors};
                let thiskeywords = {...keywords};
                supportedLanguages.map((language)=>{
                    let lang = filterLanguage(x.locales, language.slug);
                    if(lang.length){
                        //set values at editor state
                        const contentDataStateAr = ContentState.createFromBlockArray(htmlToDraft(`${lang[0].description}`));
                        const editorDataState = EditorState.createWithContent(contentDataStateAr);
                        thisEditors[language.slug] = editorDataState;
                        setEditors(thisEditors);

                        // set keywords
                        if(lang[0].meta_keywords && lang[0].meta_keywords !== 'null'){
                            thiskeywords[language.slug] = lang[0].meta_keywords.replace(/ /g, '').split(',');
                        }
                        setKeywords(thiskeywords);
                    }
                    return true
                })
                x.images = [];
                setModel(x);
                getArticlePerants(id).then((res)=>{
                    setParents(res.data.data);
                }).catch((err)=>{
                    console.log(err.response)
                })
                
            });
        }else{
            setModel({
                locales: [],
                facebook:'',
                twitter: '',
                snapchat:'',
                instagram: '',
                youtube: '',
                whatsapp: '',
                section:'',
                latitude: '',
                longitude: '',
                address_link:'',
                status: 'inactive',
                images: [],
                old_images:[]
            })
        }
        // eslint-disable-next-line
    }, [id]);

    /************************* text editor **********************/
    const convertContentToHTML = (state) => {
        return draftToHtml(convertToRaw(state.getCurrentContent()));
    }

    // onchange editor handler
    const handleEditorChange = (state,lang) => {
        textEditorValidation(lang);
        let thisEditors = {...editors}
        if(!thisEditors[lang]){ 
            thisEditors[lang]=EditorState.createEmpty()
        } 
        thisEditors[lang]=state;
        setEditors(thisEditors);
        let m = {...model}
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        })
        if(existsLocales.length){
            if(state.getCurrentContent()){
                existsLocales[0]['description'] = convertContentToHTML(state);
            }else{
                existsLocales[0]['description'] = '';
            }
        }else{
            m.locales.push({'locale': lang});
            m.locales.map((i) => {
                if(i.locale === lang){
                    if(state.getCurrentContent()){
                        i['description'] = convertContentToHTML(state);
                    }else{
                        i['description'] = '';
                    }
                }
                return true;
            });
        }
        setModel(m)
    }

    /********************* meta keywords ************************/
    function AddKeywordHandler(e,lang) {
        let propKeyword = {...keywords};
        if(e.target.value.length > 30 ){
            if(e.keyCode !== 8){
                e.preventDefault();
            }
        }
        if(e.keyCode === 32 ){
            if(!propKeyword[lang]){ 
                propKeyword[lang]=[]
            } 
            if(e.target.value.trim()){
                propKeyword[lang]=[...propKeyword[lang],e.target.value.trim()];
                setKeywords(propKeyword)
                e.target.value = ""; 

                let m ={...model}
                let existsLocales = m.locales.filter((i) => {
                    return i.locale === lang;
                })
                if(existsLocales.length){
                    existsLocales[0]['meta_keywords'] =  propKeyword[lang].join();
                }else{
                    m.locales.push({'locale': lang});
                    m.locales.map((i) => {
                        if(i.locale === lang){
                            i['meta_keywords'] =  propKeyword[lang].join();;
                        }
                        return true;
                    });
                }
            }
        }  
    
    }

    /* to delete tag to tags array*/
    function removeKeywordHandler(i,lang) {
        let propKeyword = {...keywords};
        /* JSON.parse(JSON.stringify(keywords)) */
        propKeyword[lang].splice(i,1)
        setKeywords(propKeyword) 
        let m ={...model}
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        })
        if(existsLocales.length){
            existsLocales[0]['meta_keywords'] =  propKeyword[lang].join();
        }else{
            m.locales.push({'locale': lang});
            m.locales.map((i) => {
                if(i.locale === lang){
                    i['meta_keywords'] =  propKeyword[lang].join();;
                }
                return true;
            });
        }
        setModel(m)
    }

    /* *********************** images with crop handler *********************** */
    const [openCrop, setOpenCrop] = useState(false);
    const [file, setFile] = useState(null);
    const [photoURL, setPhotoURL] = useState();
    const handleChange = (e) => {
        const file = e.target.files[0];
        if(file){
            setPhotoURL(URL.createObjectURL(file));
            setOpenCrop(true);
            setOpen(true);
            e.target.value = null;
        }
    };
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        setPhotoURL('')
    };

    useEffect(()=>{
        if(file){
            let thisModel = {...model}
            let imagesSrc = [...propImgsSrc];
            let srcObj = {file:file , src:photoURL}
            imagesSrc.push(srcObj);
            setPropImgsSrc(imagesSrc);
            thisModel.images.push(file);
        }
    },[file])
    
    //delete selected image
    function deleteImageHandler(file){
        let imagesSrc = [...propImgsSrc];
        let newSrcs = imagesSrc.filter((m)=>( m.file !== file ));
        setPropImgsSrc(newSrcs);
        let thisModel = {...model};
        let deletedImg = thisModel.images.indexOf(file);
        if(deletedImg >= 0){
            thisModel.images.splice(deletedImg, 1);
        }
        if(thisModel.old_images && thisModel.old_images.length){
            let x = thisModel.old_images.filter((m)=>( m.file !== file ));
            thisModel.old_images = x
        }
        setModel(thisModel);
    }

    /**************************** form onChange function *********************************/ 
    const setFormData = async(e ,property ,locale=false ,language=null) => {
        setArticleData(model, setModel, e, property , locale , language );
        if(property === 'section'){
            let id = e.target.value;
            let sub = sideSections.map((item)=>({...item}));
            let index = Number(e.target.getAttribute('data-i'));
            if((index+1)<=(sub.length-1)){
                sub.splice(index+1,sub.length-1)
            }
            setTimeout(function(){
                id !== '0' && getSection(id).then((res)=>{
                    let section= res.data.data
                    if(section?.sections.length){
                        getSubSections(id).then((res)=>{
                            sub.push({sections:res.data.data ,id: Date.now()});
                            setSideSections(sub); 
                        })
                    }else{
                        setSideSections(sub); 
                    }
                });
            },600)
        }
        let err = { ...errors }
        err[e.target.name] = '';
        setErrors(err)
    }

    function preventEnter(e) {
        if(e.keyCode===13){
            e.preventDefault();
        }
    }

    async function confirmArticle(e){
        e.preventDefault();
        let formData = new FormData();
        let count = 0;
        delete model.slug
        let thisModel = {...model}
        if(thisModel.locales.length){
            supportedLanguages.map((language)=>{
                let locale = filterLanguage(thisModel.locales, language.slug);
                var re = new RegExp(/^(<p>)(&nbsp;)*(<\/p>)\n$/);
                if(locale.length){
                    if(locale[0]?.name?.trim() && (locale[0]?.description && locale[0]?.description?.trim() && !re.test(locale[0]?.description))){
                        count ++;
                    }else{
                        if(!locale[0]?.name?.trim()){
                            let input = Array.from(document.querySelectorAll(`.requiredName${language.slug}`));
                            input[0].nextSibling.classList.remove("d-none");
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX ,
                            input[0].getBoundingClientRect().top + window.scrollY - 200); 
                        }
                        if(re.test(locale[0]?.description) || !locale[0]?.description?.trim()){
                            let input = Array.from(document.querySelectorAll(`.requiredDescription${language.slug}`));
                            input[0].nextSibling.classList.remove("d-none");
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX ,
                            input[0].getBoundingClientRect().top + window.scrollY - 200);
                        }if(locale[0].locale !== language.slug){
                            languageRef.current.classList.add('border-danger');
                            window.scrollTo(languageRef.current.getBoundingClientRect().left + window.scrollX ,
                            languageRef.current.getBoundingClientRect().top + window.scrollY - 150);
                        }
                    }
                }
                return true
            })
        }else{
            let classes = Array.from(errorDivRef.current.classList);
            if(classes.includes('d-none')){
                errorDivRef.current.classList.remove('d-none');
                window.scrollTo(errorDivRef.current.getBoundingClientRect().left + window.scrollX ,
                errorDivRef.current.getBoundingClientRect().top + window.scrollY - 150);
            }
        }
        if(propImgsSrc.length === 0){
            imgRef.current.nextSibling.classList.remove("d-none");
        }
        if(propImgsSrc.length !== 0 && (count === thisModel.locales.length)){
            setLoading(true); 
            for(const key in model){
                if (key === 'locales'){
                    for (let i = 0; i < thisModel.locales.length; i++){
                        for (const key2 in thisModel.locales[i]){
                            if(thisModel.locales[i][key2] !== null && thisModel.locales[i][key2] !== ''){
                                formData.append(`locales[${i}][${key2}]`, thisModel.locales[i][key2]);
                            } 
                        }
                    }
                }else if(key === 'images'){
                    for(let i=0; i < thisModel.images.length; i++){
                        formData.append(`images[${i}]`,thisModel.images[i]);
                    }                
                }else if(key === 'old_images'){
                    for(let i=0; i < thisModel.old_images.length; i++){
                        formData.append(`old_images[${i}]`,thisModel.old_images[i]['id']);
                    }                
                }else{
                    if(thisModel[key] !== '' && thisModel[key] !== null){
                        formData.append(key, thisModel[key]);
                    }else{
                        formData.append(key, '');
                    }
                }
            }
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if(submitProps === 'done'){
                setLoading(false);
                navigate(`${basename}/dashboard/articles/list`);
            }else{
                setLoading(false);
                if(submitProps?.type === 'validations'){
                    setErrors(submitProps?.error)
                }else if(submitProps?.type === 'permissions'){
                    errorDivRef.current.classList.remove("d-none");
                    errorDivRef.current.firstChild.innerText = submitProps?.error
                }
            }
        }
    }

    /* *****************************upload images at text editor*********************************** */
    let [uploadedImages,setUploadedImages] = useState([])
    async function _uploadImageCallBack(file){
        const form = new FormData();
        form.append('image', file);
        let uploadedImages2 =  [...uploadedImages] ;
        let imageObject = {}
        return await uploadImages(form).then((response) => {
            imageObject = {
                file: file,
                localSrc: response.data.data.web_image,
            }
            uploadedImages2.push(imageObject);
            setUploadedImages(uploadedImages2);
            return  new Promise(
                (resolve, reject) => {
                    resolve({ data: { link: imageObject.localSrc } });
                }
            ) 
        }); 
    } 

    return (
        <div>
            <section className='componentGlobalWrapper'>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                    <div>
                    </div>
                    <div>
                        <button ref={languageRef} className='position-relative languageDropdown ms-auto' data-bs-toggle="dropdown">
                            <img src={langIcon} alt="language icon"/>
                            <span>{t('select-language')}</span>
                            <img src={arrow} alt="arrow"/>
                        </button>
                        <div className="dropdown-menu custom-lang-dropdown">
                            <ul className="nav nav-items languageTabs d-flex flex-column">
                                {supportedLanguages.map((language, index)=>(
                                    <li key={language.slug} className='nav-item' /* onClick={()=>setSelectedLanguage(language.slug)} */>
                                        <span className={`${index === 0 && `active`} dropdownItem px-2 py-1 w-100`} id={`${language.name}Form`} data-bs-toggle="pill" data-bs-target={`#pills-${language.name}`} type="button" role="tab" aria-controls={`pills-${language.name}`} {...(index === 0 ? {'aria-selected': "true"} :{})}>
                                            {t(language.name)}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <form onKeyDown={preventEnter} onSubmit={(e) => confirmArticle(e, model)} >
                    {id && 
                        <div className='mb-3'>
                            <div className='d-inline-block marginEnd'>
                                <label className='formLabel' >{t('created_by')} : <span style={{textTransform:'none'}}> {model?.writer_name} </span></label>
                            </div>
                            -
                            {model?.editor_name && <div className='d-inline-block marginStart'>
                                <label className='formLabel'>{t('updated_by')} : <span style={{textTransform:'none'}}> {model?.editor_name} </span></label>
                            </div>}
                        </div>
                    }
                    {/*** name & description & meta keywords & meta description ***/}
                    <div className={`tab-content`} id="pills-tabContent">
                        {supportedLanguages.map((language, index)=>(
                            <div key={index} className={`tab-pane fade ${index === 0 && `active show`}`} id={`pills-${language.name}`} role="tabpanel" aria-labelledby={`${language.name}Form`}>
                                {/** title **/}
                                <div className='formWidth'>
                                    <div className='d-flex flex-column mb-4'>
                                        <label htmlFor='name' className='formLabel'>{t('title')} ({t(language.name)})</label>
                                        <input  maxLength='255' disabled={!hasSuperPermission(admin)} className={`requiredName${language.slug}`} name="name" type='text' placeholder={t("title")} value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,language.slug).length) ? filterLanguage(model.locales ,language.slug)[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , language.slug)} />
                                        <div className='validateToDelete text-danger d-none'>
                                        {t('this-field-is-required!')}
                                        </div>
                                    </div>
                                </div>
                                {/** description **/}
                                <div className='formWidth'>
                                    <div className='d-flex flex-column mb-4'>
                                        <label htmlFor='description' className='formLabel'>{t('description')} ({t(language.name)})</label>
                                        <div className={`requiredDescription${language.slug} articlesForm`}>
                                            <Editor
                                                editorState={editors[language.slug]}
                                                onEditorStateChange={(state) => handleEditorChange(state,language.slug)}
                                                toolbar =
                                                {{ 
                                                    image: { uploadCallback: _uploadImageCallBack} ,
                                                    alt: { present: true, mandatory: true }
                                                }}
                                            />
                                        </div>
                                        <div className='validateToDelete text-danger d-none'>
                                            {t("this-field-is-required!")}
                                        </div>
                                    </div>
                                </div>
                                {/** meta keywords  ***/}

                                {
                                    hasSuperPermission(admin) &&
                                    <div className='d-flex flex-column mb-4 formWidth' >
                                        <label htmlFor='metaKeyword' className='formLabel'>{t('meta-keywords')} ({t(language.name)})</label>
                                        <div ref={metaKeywordsRef} className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                                            <div className="">
                                                {
                                                    keywords[language.slug] && keywords[language.slug].map((keyword,index)=>(
                                                        <div key={index} className={`tagContainer`}>
                                                            {keyword}
                                                            <img src={removeTag} onClick={() => removeKeywordHandler(index ,language.slug)} className='removeTag' alt='remove' />
                                                        </div>
                                                    ))
                                                }
                                                <input onKeyDown={(e)=>AddKeywordHandler(e,language.slug)} maxLength='30' type="text" name="metaKeyword" className={`tagInput border-0`}  id="tags" />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/** meta description ***/}
                                <div className='d-flex flex-column mb-4 formWidth' >
                                    <label htmlFor='meta-desription' className='formLabel'>{t('meta-description')} ({t(language.name)})</label>
                                    <textarea
                                        disabled={!hasSuperPermission(admin)}
                                        maxLength='255' name="meta_description" value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,language.slug).length) ? filterLanguage(model.locales ,language.slug)[0].meta_description : ""} onChange={(e) => setFormData(e, 'meta_description' , true , language.slug)}/>
                                </div> 
                            </div>))
                        }
                    </div>
                    <div className='formWidth'>
                        <h5 className='pageSubtitle mb-4'>{t('set-location-on-map')}</h5>
                        <div className="mb-5">
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='description' className='formLabel'>{t('location-link')}</label>
                                <input
                                    disabled={!hasSuperPermission(admin)}
                                    maxLength='255' id='address_link' name="address_link" type='text' placeholder={t("location-link")} value={(model?.address_link) ? model.address_link : ""}  onChange={(e) => setFormData(e, 'address_link')}/>
                                { errors && errors.latitude && <div className='text-danger validateToDelete'>{errors.address_link}</div> }
                            </div>
                            <div className='row p-0 m-0 mb-4 w-100'>
                                {/* latitude */}
                                <div className='col-6 p-0'>
                                    <div className='d-flex flex-column w-100 marginEnd'>
                                        <label htmlFor='latitude' className='formLabel'>{t("latitude")} </label>
                                        <input
                                            disabled={!hasSuperPermission(admin)} maxLength='255' id='latitude' name="latitude" type='text' placeholder={t("latitude")} value={(model?.latitude) ? model.latitude : ""}  onChange={(e) => setFormData(e, 'latitude')}/>
                                        { errors && errors.latitude && <div className='text-danger validateToDelete'>{errors.latitude}</div> }
                                    </div>
                                </div>
                                {/* longitude */}
                                <div className='col-6 p-0'>
                                    <div className='d-flex flex-column w-100 marginStart'>
                                        <label htmlFor='longitude' className='formLabel'>{t("longitude")} </label>
                                        <input
                                            disabled={!hasSuperPermission(admin)} maxLength='255' id='longitude' name="longitude" type='text' placeholder={t("longitude")} value={(model?.longitude) ? model.longitude : ""} onChange={(e) => setFormData(e, 'longitude')}/>
                                        {errors && errors.longitude && <div className='text-danger validateToDelete'>{errors.longitude}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            hasSuperPermission(admin) &&
                        <>
                            <h5 className='pageSubtitle mb-4'>{t('contact-info')}</h5>
                            <div className='d-flex mb-4 w-100'>
                                {/* facebook */}
                                <div className='d-flex flex-column w-100 marginEnd'>
                                    <div className='d-flex align-items-center iconAndLabelContainer'>
                                        <img className='socialIcon' src={facebook} alt='facebook' />
                                        <label htmlFor='facebook' className='formLabel mb-0'>{t("facebook")} </label>
                                    </div>
                                    <input maxLength='255' id='facebook' name="facebook" type='text' placeholder={t("facebook")} value={(model?.facebook) ? model.facebook : ""}  onChange={(e) => setFormData(e, 'facebook')}/>
                                    { errors && errors.facebook && <div className='text-danger validateToDelete'>{errors.facebook}</div> }
                                </div>
                                {/* twitter */}
                                <div className='d-flex flex-column w-100 marginStart'>
                                    <div className='d-flex align-items-center iconAndLabelContainer'>
                                        <img className='socialIcon' src={twitter} alt='twitter' />
                                        <label htmlFor='twitter' className='formLabel mb-0'>{t("twitter")} </label>
                                    </div>
                                    <input maxLength='255' id='twitter' name="twitter" type='text' placeholder={t("twitter")} value={(model?.twitter) ? model.twitter : ""} onChange={(e) => setFormData(e, 'twitter')}/>
                                    { errors && errors.twitter && <div className='text-danger validateToDelete'>{errors.twitter}</div> }
                                </div>
                            </div>
                            <div className='d-flex mb-4 w-100'>
                                {/* instagram */}
                                <div className='d-flex flex-column w-100 marginEnd'>
                                    <div className='d-flex align-items-center iconAndLabelContainer'>
                                        <img className='socialIcon' src={instagram} alt='instagram' />
                                        <label htmlFor='instagram' className='formLabel mb-0'>{t("instagram")} </label>
                                    </div>
                                    <input maxLength='255' id='instagram' name="instagram" type='text' placeholder={t("instagram")} value={(model?.instagram) ? model.instagram : ""}  onChange={(e) => setFormData(e, 'instagram')}/>
                                    { errors && errors.instagram && <div className='text-danger validateToDelete'>{errors.instagram}</div> }
                                </div>
                                {/* snapchat */}
                                <div className='d-flex flex-column w-100 marginStart'>
                                    <div className='d-flex align-items-center iconAndLabelContainer'>
                                        <img className='socialIcon' src={snapchat} alt='instagram' />
                                        <label htmlFor='snapchat' className='formLabel mb-0'>{t("snapchat")} </label>
                                    </div>
                                    <input maxLength='255' id='snapchat' name="snapchat" type='text' placeholder={t("snapchat")} value={(model?.snapchat) ? model.snapchat : ""}  onChange={(e) => setFormData(e, 'snapchat')}/>
                                    { errors && errors.snapchat && <div className='text-danger validateToDelete'>{errors.snapchat}</div> }
                                </div>
                            </div>
                            <div className='d-flex mb-4 w-100'>
                                {/* youtube */}
                                <div className='d-flex flex-column  w-100 marginEnd'>
                                    <div className='d-flex align-items-center iconAndLabelContainer'>
                                        <img className='socialIcon' src={youtube} alt='youtube' />
                                        <label htmlFor='youtube' className='formLabel mb-0'>{t("youtube")} </label>
                                    </div>
                                    <input maxLength='255' id='youtube' name="youtube" type='text' placeholder={t("youtube")} value={(model?.youtube) ? model.youtube : ""}  onChange={(e) => setFormData(e, 'youtube')}/>
                                    { errors && errors.youtube && <div className='text-danger validateToDelete'>{errors.youtube}</div> }
                                </div>

                                {/* website */}
                                <div className='d-flex flex-column  w-100 marginStart'>
                                    <div className='d-flex align-items-center iconAndLabelContainer'>
                                        <img className='socialIcon' src={website} alt='website' />
                                        <label htmlFor='website' className='formLabel mb-0'>{t("website")} </label>
                                    </div>
                                    <input maxLength='255' id='website' name="website" type='text' placeholder={t("website")} value={(model?.website) ? model.website : ""}  onChange={(e) => setFormData(e, 'website')}/>
                                    { errors && errors.website && <div className='text-danger validateToDelete'>{errors.website}</div> }
                                </div>
                            </div>

                            <div className='d-flex mb-5 w-100'>
                                {/* whatsapp */}
                                <div className="d-flex flex-column w-100 marginEnd">
                                    <div className='d-flex align-items-center iconAndLabelContainer'>
                                        <img className='socialIcon' src={whatsapp} alt='whatsapp' />
                                        <label htmlFor="whatsapp" className={`form-label mb-0`}>{t('whatsapp')}</label>
                                    </div>
                                    <PhoneInput dir='ltr' countryCodeEditable={true} {...(model && model.whatsapp ? {'value': model && model.whatsapp }:{})} className='w-100 phoneInput' id="phone" name='phone' onChange={(e) => model.whatsapp=e}/>
                                    { errors && errors.whatsapp && <div className='text-danger validateToDelete'>{errors.whatsapp}</div> }
                                </div>
                                <div className="d-flex flex-column w-100 marginStart">

                                </div>
                            </div>

                            <div className='selectForm'>
                                {/* sections */}
                                <div className='d-flex flex-column mb-4 w-100'>
                                    <label className='formLabel'>{t('section')}</label>
                                    {id && parents && parents.map((sec)=>{
                                        return `${sec} / `
                                    })}

                                    {/*  */}
                                    {sideSections ? sideSections.map((sec,i)=>{
                                            return(
                                                <div className='selectParent mt-2' key={sec.id} >
                                                    <select data-i={i} defaultValue={0} name='section' placeholder={t('section')} onChange={(e) =>  setFormData(e, 'section')}>
                                                        <option value={0} className='defaultOption' disabled>{id?t('edit-section'):t('select-section')}</option>
                                                        {sec.sections && sec.sections.map((section , index)=>{
                                                            let language = i18n.language;
                                                            let locale = filterLanguage(section.locales ,language);
                                                            return <option value={section.id} key={index}>{locale[0]?.name ? locale[0]?.name : section.locales[0]?.name }</option>
                                                        })}
                                                    </select>
                                                </div>
                                            )})
                                        :''}
                                    {errors.section && errors.section.length && <div className='validateToDelete text-danger '>
                                        {errors.section}
                                    </div>
                                    }
                                </div>
                            </div>
                        </>
                        }

                    </div>
                    {/* location in map  */}

                    {
                        hasSuperPermission(admin) &&
                    <div  className='formWidth'>
                        {/*** images ***/}
                        <div className="row m-0 mb-5 d-flex align-items-center ">
                            <label className='formLabel p-0 mb-0'>{t('images')}</label>
                            {propImgsSrc &&
                                propImgsSrc.map((img , i)=>{
                                    return(
                                        <div key={i} className='globalImageWrapper position-relative p-1 mt-2'>
                                            <Tooltip title='Remove Image'>
                                                <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                                    <img src={removeIcon} alt="delete" onClick={()=>deleteImageHandler(img.file)}/>
                                                </label>
                                            </Tooltip>
                                            <div className='globalPreviewedImage' style={{backgroundImage: `url(${img.src})` , 'backgroundSize': 'cover', 'width' : '100%' , 'height' : '100%'}}></div>
                                        </div>
                                    )})
                            }
                            <div ref={imgRef} className='imageWrapper p-0 mt-2' style={{"width" : "180px" , "height" : "140px"}}>
                                <label htmlFor='propertyImg' className='h-100 w-100 d-flex flex-column justify-content-center align-items-center'>
                                    <img width='90px' height='80px' src={uploadImage} alt='upload' />
                                    <input onChange={handleChange} name="images" type="file" className={`d-none`} id="propertyImg"/>
                                    <span> {t('upload-image')} </span>
                                </label>
                            </div>
                            <div className='text-danger mt-1 d-none validateToDelete px-0'>
                                {t('upload-at-least-1-image-required!')}
                            </div>
                        </div>

                        {/** activate article ***/}
                        {Permissions?.articles && Permissions?.articles['toggle-active'] && <div className='d-flex mb-4'>
                            <div className='d-flex align-items-center'>
                                <label className="switch mb-0">
                                    <input type="checkbox" name='status'
                                           id='statusCheck' onChange={(e) => setFormData(e, 'status')} />
                                    <span className="slider round" />
                                </label>
                                <label htmlFor='active_status' className='formLabel mb-0 labelForCheck'>{t('active')}</label>
                            </div>
                        </div>}

                        <div ref={errorDivRef} className="row mb-4 d-none">
                            <div className='alert alert-danger validateToDelete'>
                                {t('you-should-enter-the-article-data-in-one-language-at-least!')}
                            </div>
                        </div>

                    </div>
                    }

                    {/* loader and submit btn */}
                    <div>
                        {!loading?
                            <button className='confirmBtn propSubmit' type='submit'>{id ? t('save-changes') : t('add-article')}</button>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                {t('saving')}...
                            </button>
                        }
                    </div>
                </form>
            </section>

            <div>
                {openCrop && 
                    <Dialog open={open} onClose={handleClose}>
                        <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL, setFile }} />
                    </Dialog>
                }
            </div>
        </div>
    )
}
