import React, {useEffect, useRef, useState} from 'react'
import Select from 'react-select'
import {assignArticlePermission} from "../Apis/articles";

export default function AssignModal({articleId, assignedAdminIds, allAdmins, onUpdate}) {
    const closeDeleteModalRef = useRef();
    const [assignedIds, setAssignedIds] = useState([]);
    const [options, setOptions] = useState([]);

    const handleChange = (data)=>{
        setAssignedIds(data)
    }

    const saveAssignedIds = (e) =>{
        const data = {
          admin_ids : assignedIds.map(a => a.value)
        }
        assignArticlePermission(articleId, data).then(res => {
            onUpdate();
            closeDeleteModalRef.current.click();
        }).catch(e=> alert(e.message))
    }

    useEffect(()=>{
        let admins = allAdmins.map(admin=> ({ value: admin.id, label: admin.name}))
        setOptions(admins);
        let assigned = admins.filter(a => (assignedAdminIds?.indexOf(a.value) !== -1))
        if(assignedAdminIds && assigned){
            setAssignedIds(assigned);
        }
    }, [allAdmins, assignedAdminIds])


    return (
        <div>
            <div className="modal fade" id="assignModal"   tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div ref={closeDeleteModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close"
                          >
                    </div>
                    <div className="modal-content">
                        <div className="modal-body pt-3">
                            <div className={"form-group"}>
                                <h3 className={"text-black fs-3"}> Assign users </h3>

                                <Select
                                    isMulti
                                    value={assignedIds}
                                    onChange={d => handleChange(d)}
                                    className={"select-multiple p-0"}
                                    options={options}/>
                            </div>
                            <button className={"btn btn-primary mt-3 btn-lg w-100"} onClick={saveAssignedIds} > Save </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
