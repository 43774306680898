import React, { useContext } from 'react';
import './overview.scss';
import {  useState,  useEffect } from 'react';
import {useLocation} from 'react-router';
import { Link } from 'react-router-dom';
import { getStatistics } from '../../Apis/overview';
import {getArticles} from '../../Apis/articles'
import { filterLanguage } from '../../utilities/filterLang';
import TitleContext from '../../Contexts/TitleContext';
import { basename } from '../../Configuration/config';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export default function Overview() {
    const { pathname } = useLocation();
    let {changeTitle} = useContext(TitleContext);
    const {i18n} = useTranslation(); 
    const [overview,Setoverview]=useState({});
    const [latestBlogs ,setLatestBlogs] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get all statistics
    useEffect(() => {
        changeTitle(t(`overview`));
        getStatistics().then((res) => {
        let over = { ...overview }
        over = res.data.data;
            Setoverview(over)
        });
        // get latest blogs
        getArticles('per_page=7').then(res => {
            let mylatestBlogs = [...latestBlogs]
            mylatestBlogs = res.data.data
            setLatestBlogs(mylatestBlogs)
        })
        // eslint-disable-next-line
    }, [i18n.language])

    return (
        <div className='componentGlobalWrapper'>
            <div className="row row-cols-2 row-cols-lg-3 g-5 mb-5 d-flex justify-content-center">
                <div className='col'>
                    <Link to={`${basename}/dashboard/contact-us?status=unread`} className='overViewCard h-100'>
                        <span className='details'>{t('unread-contacts')}</span>
                        <span className='number'>{overview?.unread_contact_us_number}</span>
                    </Link>
                </div>
                <div className='col'>
                    <Link to={`${basename}/dashboard/sections/main`} className='overViewCard h-100'>
                        <span className='details'>{t('main-sections')} </span>
                        <span className='number'>{overview?.main_section_number}</span>
                    </Link>
                </div>
                <div className='col'>
                    <Link to={`${basename}/dashboard/admins/admins-list`} className='overViewCard h-100'>
                        <span className='details'>{t('admins')}</span>
                        <span className='number'> {overview?.admins_num}</span>
                    </Link>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-1 g-4 d-flex justify-content-center">
                <div className='col'>
                    <div className='latestData pb-0 h-100'>
                        <div className="latestHeader">
                            <p>{t('latest-articles')}</p>
                            <Link to={`${basename}/dashboard/articles/list`}>{t('articles-list')}</Link>
                        </div>
                        <div className="allTitlesOfLatest">
                            {latestBlogs.map((blog)=> {
                                let en = filterLanguage(blog.locales,'en');
                                let ar = filterLanguage(blog.locales,'ar');
                                return(
                                    <div key={blog.slug} className="oneOfLatest" dir={en.length ? 'ltr' : 'rtl'}>
                                        <div className="titleOfLatest">
                                            <Link className='articleLinks' to={`${basename}/dashboard/articles/${blog.id}/update`} >{ en.length ? en[0].name : ar.length ? ar[0].name:''}</Link>
                                        </div>
                                        <hr/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
