import {t} from 'i18next';
import React, {useState, useEffect, useContext /* , useRef */} from 'react';
import '../tables.scss';
import {useLocation} from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../assets/icons/edit.svg";
import undelete from "../../assets/icons/undelete.svg";
import delete_icon from "../../assets/icons/delete.svg";
import {
    deleteArticle,
    deleteTrashedArticle,
    getArticles,
    getTrashedArticles, revokeArticlePermission /* ,toggleArticleStatus */,
    undeleteArticle
} from '../../Apis/articles';
import {getStatistics} from '../../Apis/overview';
import {basename} from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import {Link /* , useSearchParams */} from 'react-router-dom';
import DeleteModal from '../../utilities/deleteModal';
import AssignModal from "../../utilities/AssignModal";
import i18n from './../../i18n';
import moment from 'moment';
import Pagination from './../../Components/Pagination/Pagination';
import search from "../../assets/icons/search.svg";
import '../forms.scss';
import {filterLanguage} from '../../utilities/filterLang';
import PermissionsContext from './../../Contexts/permissionsContext';
import {getAdminProfileInfo} from '../../Apis/profile';
import {getAdmins} from "../../Apis/admins";
import ROLES_NUMBERS from "../../constants/ROLES_NUMBERS";
import {hasSuperPermission} from "../../utilities/helpers";

export default function ArticlesList() {
    let {Permissions} = useContext(PermissionsContext);
    const [allArticles, setAllArticles] = useState([]);
    const [deletedObj, setDeletedObj] = useState('');
    const {pathname} = useLocation();
    const {changeTitle} = useContext(TitleContext);
    const [filterObj, setfilterObj] = useState({per_page: 10, page: 1, search: '', status: '', trashed: 0, admin: ''});
    const [pageCount, setPageCount] = useState(1)
    const [totalArticles, setTotalArticles] = useState(0);
    const [totalActiveArticles, setTotalActiveArticles] = useState(0);
    const [totalInActiveArticles, setTotalInActiveArticles] = useState(0);
    const [totalTrashedArticles, setTotalTrashedArticles] = useState(0);
    const [adminId, setAdminId] = useState(null);
    const [admin, setAdmin] = useState(null);

    const [assignArticleId, setAssignArticleId] = useState(null);
    const [assignedAdminIds, setAssignedAdminIds] = useState(null);
    const [allAdmins, setAdmins] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        if (i18n.language === 'ar') {
            moment.updateLocale('ar', {
                months: ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر']
            });
        } else {
            moment.updateLocale('en', {
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            });
        }
        // eslint-disable-next-line
    }, [i18n.language])

    useEffect(() => {
        changeTitle(t(`articles`), Permissions?.articles?.create && {url: 'articles/create', text: t('add-article')});
        getAdminProfileInfo().then((res) => {
            setAdmin(res.data.data);
            setAdminId(res.data.data.id);
            if (hasSuperPermission(res.data.data)) {
                getAdmins().then(res => {
                    setAdmins(res.data.data);
                })
            }
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (adminId !== null) {
            getAllArticles();
        }

        // eslint-disable-next-line
    }, [adminId, allAdmins]);

    useEffect(() => {
        getStatistics().then((res) => {
            setTotalArticles(res.data.data.articles_number);
            setTotalActiveArticles(res.data.data.active_articles_number);
            setTotalTrashedArticles(res.data.data.trashed_articles_number);
            setTotalInActiveArticles(res.data.data.inactive_articles_number)
        })
    }, [allArticles])

    /* get all articles */
    function getAllArticles() {
        if ( hasSuperPermission(admin) && !allAdmins.length) return;
        let filter = {...filterObj}
        filter.status = '';
        filter.trashed = 0;
        filter.page = 1;
        filter.admin = adminId
        setfilterObj(filter);
        let obj;
        if (Permissions?.articles?.show_his_own_subjects) {
            obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}&admin=${filter.admin}`;
        } else {
            obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}`;
        }
        getArticles(obj).then((res) => {
            const allArticles = res.data.data;
            allArticles.map(article => {
                article.permissions = [];
                allAdmins.forEach(admin => {
                    const perm = admin.permissions.filter(p => p.name === 'articles.edit.' + article.id);
                    if (perm.length)
                        article.permissions.push(admin);
                })
            })
            setAllArticles(res.data.data);
            let total = res.data.pagination.total;
            setPageCount(Math.ceil(total / filter.per_page));

            /* setTotal(total) */
        }).catch((err) => (console.log(err)))
    }

    /* get active articles */
    function getPublish() {
        let filter = {...filterObj}
        filter.status = 'active';
        filter.trashed = 0;
        filter.page = 1;
        filter.admin = adminId
        setfilterObj(filter)
        let obj;
        if (Permissions?.articles?.show_his_own_subjects) {
            obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}&admin=${filter.admin}`;
        } else {
            obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}`;
        }
        getArticles(obj).then((res) => {
            setAllArticles(res.data.data);
            let total = res.data.pagination.total;
            setPageCount(Math.ceil(total / filter.per_page));
        }).catch((err) => (console.log(err)))
    }

    /* get active articles */
    function getUnpublish() {
        let filter = {...filterObj}
        filter.status = 'inactive';
        filter.trashed = 0;
        filter.page = 1;
        filter.admin = adminId
        setfilterObj(filter)
        let obj;
        if (Permissions?.articles?.show_his_own_subjects) {
            obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}&admin=${filter.admin}`;
        } else {
            obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}`;
        }
        getArticles(obj).then((res) => {
            setAllArticles(res.data.data);
            let total = res.data.pagination.total;
            setPageCount(Math.ceil(total / filter.per_page));
        }).catch((err) => (console.log(err)))
    }

    /* get trashed articles */
    function getTrashed() {
        let filter = {...filterObj}
        filter.status = '';
        filter.trashed = 1;
        filter.page = 1;
        filter.admin = adminId
        setfilterObj(filter);
        let obj;
        if (Permissions?.articles?.show_his_own_subjects) {
            obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}&admin=${filter.admin}`;
        } else {
            obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}`;
        }
        getTrashedArticles(obj).then((res) => {
            let total = res.data?.pagination?.total;
            setAllArticles(res.data.data);
            /* setTotal(total); */
            setPageCount(Math.ceil(total / filter.per_page));
        }).catch((err) => (console.log(err.response)))
    }

    /* delete article */
    const onSubmitDelete = async () => {
        let obj = {...deletedObj}
        if (!obj.trashed) {
            return await deleteArticle(obj.id).then((res) => {
                let filter = {...filterObj}
                let obj;
                if (Permissions?.articles?.show_his_own_subjects) {
                    obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}&admin=${filter.admin}`;
                } else {
                    obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}`;
                }
                getArticles(obj).then((res) => {
                    setAllArticles(res.data.data);
                    let total = res.data.pagination.total;
                    /* setTotal(total) */
                    setPageCount(Math.ceil(total / filter.per_page))
                }).catch((err) => (console.log(err)))
                return 'done';
            }).catch((err) => {
                return err.response.data.message
            })
        } else {
            return await deleteTrashedArticle(obj.id).then((res) => {
                getTrashed()
                return 'done';
            }).catch((err) => {
                return err.response.data.message
            })
        }
    }

    const unAssignAdmin = (adminId, topicId) => {
        const data = {
            admin_id: adminId
        }
        revokeArticlePermission(topicId, data).then((res) => {
            document.getElementById(`badge-${adminId}-${topicId}`).remove();
        })
    }

    const handlepageChange = async data => {
        let filter = {...filterObj};
        filter.page = data.selected + 1;
        setfilterObj(filter);
        let obj;
        if (Permissions?.articles?.show_his_own_subjects) {
            obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}&admin=${filter.admin}`;
        } else {
            obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}`;
        }
        getArticles(obj).then((res) => {
            setAllArticles(res.data.data);
        }).catch((err) => (console.log(err)))
    }

    function changeFilterObj(e) {
        let thisObj = {...filterObj}
        thisObj[e.target.name] = e.target.value.trim();
        thisObj.page = 1
        setfilterObj(thisObj);
    }

    function getFiltered(e) {
        getAllArticles()
    }

    function restore(id) {
        undeleteArticle(id).then((res) => {
            getTrashed();
        });
    }

    const assignAdminsForArticle = (article) => {
        let ids = article.permissions.map((admin) => admin.id)
        setAssignedAdminIds(ids);
    }

    return (
        <>
            {Permissions && Permissions.articles && allAdmins &&
                <section className='componentGlobalWrapper d-flex flex-column'>
                    <div>
                        <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab"
                             role="tablist">
                            <div onClick={getAllArticles} className='tablinks active' id="Gallery-tab"
                                 data-bs-toggle="tab"
                                 data-bs-target="#allnews">{t('all-articles')} <span
                                className='tabcounter'>({totalArticles})</span></div>
                            <span className='separator'></span>
                            <div onClick={getPublish} className='tablinks' id="published-tab" data-bs-toggle="tab"
                                 data-bs-target="#published-Img">{t('published')} <span
                                className='tabcounter'>({totalActiveArticles})</span></div>
                            <span className='separator'></span>
                            <div onClick={getUnpublish} className='tablinks' id="unpublished-tab" data-bs-toggle="tab"
                                 data-bs-target="#unpublished-Img">{t('unpublished')} <span
                                className='tabcounter'>({totalInActiveArticles})</span></div>
                            <span className='separator'></span>
                            <div onClick={getTrashed} className='tablinks' id="trashed-tab" data-bs-toggle="tab"
                                 data-bs-target="#trashed-Img">{t('trashed')} <span
                                className='tabcounter'>({totalTrashedArticles})</span></div>
                        </div>
                        <div className='searchActionsBar w-100'>
                            <div className='searchActionsBar mt-0 mb-0'>
                                {/*** search input ***/}
                                <div className='searchInputWrapper'>
                                    <div>
                                        <input type='text' className='barSearchInput' name='search'
                                               onChange={changeFilterObj}/>
                                    </div>
                                    <img src={search} alt='search-icon' className='barSearchIcon'/>
                                </div>
                            </div>
                            {/* show select */}
                            <div className='perPageContainer'>
                                <label htmlFor='selectShow' className='perPageLabel'>
                                    {t('show')}
                                </label>
                                <select name='per_page' onChange={changeFilterObj} id={`selectShow`}
                                        className='form-select selectShow' defaultValue='10'>
                                    <option value='5'>5</option>
                                    <option value='10'>10</option>
                                    <option value='15'>15</option>
                                    <option value='20'>20</option>
                                </select>
                            </div>
                            {/*** apply search btn  ***/}
                            <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
                        </div>
                        {Permissions.articles?.show && <div className='tableWrapper'>
                            <table className="w-100 table table-striped ">
                                <thead>
                                <tr>
                                    <th>{t('image')}</th>
                                    <th>{t('title')}</th>
                                    {
                                        hasSuperPermission(admin) &&
                                        <th>{t('assign admin')}</th>
                                    }
                                    <th>{t('section')}</th>
                                    <th>{t('created-at')}</th>
                                    <th>{t('details')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {allArticles && allArticles.map((article, index) => {
                                    let lang = i18n.language;
                                    let obj = filterLanguage(article?.locales, lang);
                                    let sec = filterLanguage(article?.section?.locales, lang);
                                    return (
                                        <tr key={index}>
                                            <td className='sliderImg' width={'200px'}>
                                                <img src={`${article?.images?.url}`} alt="slider"/>
                                            </td>
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    {article?.locales && obj.length ? obj[0]?.name : article?.locales[0]?.name}
                                                </div>
                                            </td>
                                            <td>
                                                {article?.section?.locales && sec.length ? sec[0]?.name : article?.section?.locales?.length ? article?.section?.locales[0]?.name : ''}
                                            </td>
                                            {
                                                hasSuperPermission(admin) && <td>
                                                    <div className={"d-flex flex-wrap"} style={{width: '200px'}}>
                                                        {article.permissions &&
                                                            article.permissions.map((admin, key) =>
                                                                <div key={key} className={"mb-1 me-2"}>
                                                                <span className={"badge bg-success "}
                                                                      id={`badge-${admin.id}-${article.id}`}> {admin.name}
                                                                    <a className={""}
                                                                       onClick={() => unAssignAdmin(admin.id, article.id)}>

                                                                    <span className={"badge bg-danger px-1"}
                                                                          style={{
                                                                              margin: ' -10px',
                                                                              marginInlineStart: '5px',
                                                                              fontSize: '1em'
                                                                          }}
                                                                    >x</span>
                                                                    </a>
                                                                </span>

                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                    <Tooltip title={t('assignAdmin')}>
                                                        <button className={"btn btn-primary btn-sm me-1"}
                                                                data-bs-toggle="modal"
                                                                data-bs-target='#assignModal'
                                                                onClick={() => {
                                                                    setAssignArticleId(article.id);
                                                                    assignAdminsForArticle(article)
                                                                }}>
                                                            Assign admin
                                                        </button>
                                                    </Tooltip>


                                                </td>
                                            }

                                            <td>
                                                {moment(article.created_at).format('DD-MMMM-YYYY')}
                                            </td>
                                            <td>
                                                {article.writer_name && <div className='mb-2'>
                                                    {t('created_by')} : {article.writer_name}
                                                </div>}
                                                {article.editor_name && <div>
                                                    {t('updated_by')} : {article.editor_name}
                                                </div>}
                                            </td>
                                            <td>
                                                {filterObj && filterObj.trashed === 1 ? <div>
                                                        {Permissions.articles?.destroy && <Tooltip title={t('restore')}>
                                                            <img width='18px' height='20px' src={undelete}
                                                                 onClick={() => restore(article.id)} alt='undelete'/>
                                                        </Tooltip>}
                                                        {Permissions.articles?.destroy && <Tooltip title={t('delete')}>
                                                            <img src={delete_icon} alt="delete" data-bs-toggle="modal"
                                                                 data-bs-target='#deleteModal'
                                                                 onClick={() => setDeletedObj({
                                                                     'id': article.id,
                                                                     'trashed': true
                                                                 })}/>
                                                        </Tooltip>}
                                                    </div>
                                                    :
                                                    <div>
                                                        {/* {Permissions?.articles['toggle-active'] && <label className="switch mb-0">
                                                        <input type="checkbox"  id={article.id}  onChange={()=> toggleStatus(article)}  />
                                                        <span className="slider round" />
                                                    </label>} */}
                                                        {Permissions.articles?.update && <Tooltip title={t('edit')}>
                                                            <Link
                                                                to={`${basename}/dashboard/articles/${article.id}/update`}>
                                                                <img src={edit_icon} alt="edit"/>
                                                            </Link>
                                                        </Tooltip>}
                                                        {Permissions.articles?.destroy && <Tooltip title={t('delete')}>
                                                            <img src={delete_icon} alt="delete" data-bs-toggle="modal"
                                                                 data-bs-target='#deleteModal'
                                                                 onClick={() => setDeletedObj({
                                                                     'id': article.id,
                                                                     'trashed': false
                                                                 })}/>
                                                        </Tooltip>}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                            <DeleteModal onSubmitDelete={onSubmitDelete} name='article'></DeleteModal>
                            <AssignModal allAdmins={allAdmins} onUpdate={() => {
                                getAdmins().then((res) => setAdmins(res.data.data));
                            }}
                                         name='article' articleId={assignArticleId}
                                         assignedAdminIds={assignedAdminIds}></AssignModal>
                        </div>}
                    </div>
                    {<div className='d-flex justify-content-end mt-auto'>
                        <Pagination pagesNumber={pageCount} pageChangeHandler={handlepageChange}/>
                    </div>}
                </section>}
        </>
    )
}
