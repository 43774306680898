import React, { useContext, useEffect } from 'react';
import TitleContext from '../../../Contexts/TitleContext';
import { t } from 'i18next';
import { getGeneralSettings, updateGeneralSettings } from '../../../Apis/generalSettings';
import { useState } from 'react';
import { supportedLanguages } from './../../../utilities/SupportedLanguages';
import { filterLanguage } from './../../../utilities/filterLang';

export default function Contactus() {
    let { changeTitle } = useContext(TitleContext);
    let [contactData, setContactData] = useState({})
    let [errors, setErrors] = useState({})
    let [loader , setLoader] = useState(false);

    useEffect(() => {
        changeTitle(t(`contact-us`));
        getGeneralSettings().then((res) => {
            let data = res.data.data;
            let contact = {
                address: data.address,
                contact_us: data.contact_us,
                email: data.email,
                facebook: data.facebook,
                instagram: data.instagram,
                linkedin: data.linkedin,
                twitter: data.twitter,
                phone: data.phone
            }
            setContactData(contact)
        })
        // eslint-disable-next-line
    }, []);
    function changeHandler(e,i,lang) {
        let contact={...contactData}
        let err = { ...errors }
        if(e.target.name === 'contact_us'){
            contact[e.target.name][i] = {name:e.target.value,locale:lang};  
        //    err[e.target.name][i] = {name:'',locale:lang};  
        }else{
            contact[e.target.name]= e.target.value
            err[e.target.name]=''
        }
        setErrors(err)
        setContactData(contact)
        
    }

    function changeContactSubmit(e){
        e.preventDefault();
        setLoader(true)
        updateGeneralSettings(contactData).then(()=>{
        setLoader(false)
        }).catch((error)=>{
        setLoader(false)
            let err = { ...errors }
            Object.entries(error.response.data.errors).map(([key, value]) => {
                err[key] = value;
                return true;
            });
            setErrors(err);
        })
    }

    function preventEnter(e) {
        if(e.keyCode===13){
            e.preventDefault()
        }
    }
    return (
        <div className='componentGlobalWrapper'>
            <form className='pagesForm' onSubmit={changeContactSubmit} onKeyDown={preventEnter}>
                {supportedLanguages.map((language,i) => {
                    return (
                        <div key={i} className='d-flex flex-column mb-4'>
                            <label htmlFor={`contactName`} className='formLabel'>{t('name')} ({t(language.name)}) </label>
                            <input type='text' onChange={(e) => changeHandler(e, i,language.slug)} maxLength='250' minLength='3' name="contact_us" id={`contactName`} value={(contactData?.contact_us && filterLanguage(contactData?.contact_us, language.slug)[0]?.name) ??''} />
                            <div className='text-danger'>{errors?.contact_us && filterLanguage(errors?.contact_us, language.slug)[0]?.name} </div>
                        </div>
                    )
                })
                }
                  <div  className='d-flex flex-column mb-4'>
                    <label htmlFor={`facebook`} className='formLabel'>{t('facebook')} </label>
                    <input type='text' onChange={(e) => changeHandler(e)} maxLength='250' minLength='3' name="facebook" id={`facebook`} value={contactData?.facebook ??''} />
                    <div className='text-danger'>{errors.facebook} </div>
                </div>
                <div  className='d-flex flex-column mb-4'>
                    <label htmlFor={`twitter`} className='formLabel'>{t('twitter')} </label>
                    <input type='text' onChange={(e) => changeHandler(e)} maxLength='250' minLength='3' name="twitter" id={`twitter`} value={contactData?.twitter ??''} />
                    <div className='text-danger'>{errors.twitter} </div>
                </div>
                <div  className='d-flex flex-column mb-4'>
                    <label htmlFor={`instagram`} className='formLabel'>{t('instagram')} </label>
                    <input type='text' onChange={(e) => changeHandler(e)} maxLength='250' minLength='3' name="instagram" id={`instagram`} value={contactData?.instagram ??""} />
                    <div className='text-danger'>{errors.instagram} </div>
                </div>
                <div  className='d-flex flex-column mb-4'>
                    <label htmlFor={`linkedin`} className='formLabel'>{t('linkedin')} </label>
                    <input type='text' onChange={(e) => changeHandler(e)} maxLength='250' minLength='3' name="linkedin" id={`linkedin`} value={contactData?.linkedin ?? ""} />
                    <div className='text-danger'>{errors.linkedin} </div>
                </div>
                <div  className='d-flex flex-column mb-4'>
                    <label htmlFor={`email`} className='formLabel'>{t('email')} </label>
                    <input type='text' onChange={(e) => changeHandler(e)} maxLength='250' minLength='3' name="email" id={`email`} value={contactData?.email ??''} />
                    <div className='text-danger'>{errors.email} </div>
                </div>
                <div  className='d-flex flex-column mb-4'>
                    <label htmlFor={`phone`} className='formLabel'>{t('phone')} </label>
                    <input type='text' onChange={(e) => changeHandler(e)} maxLength='250' minLength='3' name="phone" id={`phone`} value={contactData?.phone ??''} />
                    <div className='text-danger'>{errors.phone} </div>
                </div>
                <div  className='d-flex flex-column mb-4'>
                    <label htmlFor={`address`} className='formLabel'>{t('address')} </label>
                    <input type='text' onChange={(e) => changeHandler(e)} maxLength='250' minLength='3' name="address" id={`address`} value={contactData?.address ?? ''} />
                    <div className='text-danger'>{errors.address} </div>
                </div>
                {!loader ?
                            <div>
                                <button className='confirmBtn' type='submit'>{t('save-changes')}</button>
                            </div>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {t('saving')}...
                            </button>
                        }
            </form>
        </div>
    )
}
