import { t } from 'i18next';
import React , {useState , useEffect ,useContext} from 'react';
import '../tables.scss';  
import { useLocation, useParams } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import { deleteSection, getSectionPerants, getSubSections ,toggleActivate} from '../../Apis/sections';
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import DeleteModal from '../../utilities/deleteModal';
import i18n from '../../i18n';
import moment from 'moment';
import Pagination from '../../Components/Pagination/Pagination';
import search from "../../assets/icons/search.svg";
import '../forms.scss';
import { filterLanguage } from '../../utilities/filterLang';
import PermissionsContext  from './../../Contexts/permissionsContext';

export default function SubSections() {
    const {Permissions} = useContext(PermissionsContext);
    const {id} = useParams();
    const [allSections , setAllSections] = useState([]);
    const [deletedId ,setDeletedId] = useState('')
    const {pathname} = useLocation();
    const {changeTitle} = useContext(TitleContext);
    const [filterObj, setfilterObj]= useState({per_page:10,page:1,search:'',status:''});
    const [pageCount ,setPageCount] = useState(1)
    const [total ,setTotal] = useState(0);
    const [parents,setParents] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        
        // eslint-disable-next-line
    },[pathname]);

    useEffect(() => {
        if(i18n.language === 'ar'){
            moment.updateLocale('ar', {
                months: [ 'يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر' ]
            });
        }else{
            moment.updateLocale('en', {
                months: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
            });
        }
        // eslint-disable-next-line
    },[i18n.language])

    useEffect(() => {
        getAllSections()
        changeTitle(t(`sub-sections`),Permissions?.sections?.create && {url: 'sections/create', text: t('add-section')});
        // eslint-disable-next-line
    },[id])

    /* get all articles */
    function getAllSections(){
        let filter = {...filterObj}
        let obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}`
        getSubSections(id,obj).then((res) => {
            let artData = res.data.data;
            console.log(res.data.data);
            setAllSections(res.data.data);
            artData.map((sec) => {
                if(sec.status === 'active'){
                    document.getElementById(sec.id).checked = true;
                }else{
                    document.getElementById(sec.id).checked = false;
                }
                return true
            });
            let x = res.data.data[0]?.parents?.map((p)=>{
                if(p[i18n.language]){
                    return `${p[i18n.language].name}`
                }else{
                    for(const key in p){
                        return `${p[key].name}`
                    }
                }
                return true
            })
            setParents(x)
            let total = res.data.pagination.total;
            setTotal(total)
            setPageCount(Math.ceil(total/filter.per_page));
            
        }).catch((err)=>(console.log(err))) 
    }  

    /* delete article */
    const onSubmitDelete = async ()=>{
        return await deleteSection(deletedId).then((res) => {
            getAllSections()
            return 'done';
        }).catch((err)=>{
            return err.response.data.message
        })
    }

    const handlepageChange= async data =>{
        let filter = {...filterObj};
        filter.page = data.selected+1;
        setfilterObj(filter);
        let obj=`search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}&page=${data.selected+1}&status=${filterObj && filterObj.status}`;
        getSubSections(id,obj).then((res) => {
            setAllSections(res.data.data);
        }).catch((err)=>(console.log(err)))
    }

    function changeFilterObj(e){
        let thisObj = {...filterObj}
        thisObj[e.target.name]= e.target.value.trim();
        thisObj.page = 1
        setfilterObj(thisObj);
    }

    function getFiltered(e){
        getAllSections()
    }

    function toggleStatus(id) {
        toggleActivate(id).then((res)=>{
            console.log(res.data.data);
        }).catch((err)=>{
            console.log(err.response);
        })
    }

    return (
        <>
            {Permissions && Permissions.sections && <section className='componentGlobalWrapper d-flex flex-column'>
                {/*  */}
                <div>
                    <div className='d-flex parentTitles'>
                        {parents?.map((p,index)=>(
                            <span key={index} className='sectionParentName'> {p} / </span> 
                            )) 
                        }
                    </div>
                    <div className='searchActionsBar w-100'>
                        <div className='searchActionsBar mt-0 mb-0'>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon'/>
                            </div>
                        </div>
                        {/* show select */}
                        <div className='perPageContainer'>
                            <label htmlFor='selectShow' className='perPageLabel'>
                                {t('show')}
                            </label>
                            <select name='per_page' onChange={changeFilterObj}  id={`selectShow`} className='form-select selectShow' defaultValue='10'>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                            </select>
                        </div>
                        {/*** apply search btn  ***/}
                        <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
                    </div>
                    {Permissions?.sections?.show && <div className='tableWrapper'>
                        <table className="w-100 table table-striped ">
                            <thead>
                                <tr>
                                    <th>{t('image')}</th>
                                    <th>{t('name')}</th>
                                    <th>{t('sub-sections')}</th>
                                    <th>{t('created-at')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allSections && allSections.map((section , index)=>{
                                    let lang =i18n.language
                                    let obj = filterLanguage(section.locales ,lang)[0];
                                    return(
                                        <tr key={index}>
                                            {/* image */}
                                            <td className='sliderImg' style={{"width" : "23%"}}>
                                                <img src={`${section?.image?.url}`} alt="slider"/>
                                            </td>
                                            {/* name */}
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    { section?.locales && obj ? obj.name : section.locales[0]?.name}
                                                </div>
                                            </td>
                                            {/* sub sections */}
                                            <td className='showAllSubSections'>
                                                    {
                                                        section?.sections === true ?
                                                        <Link to={`${basename}/dashboard/sections/${section.id}/sub-sections`}>
                                                            {t('show-all')}
                                                        </Link>
                                                        :
                                                        '-'  
                                                    }
                                                
                                            </td>
                                            {/* date */}
                                            <td>
                                                {moment(section.created_at).format('DD-MMMM-YYYY')}
                                            </td>
                                            <td>
                                                <div>
                                                    {Permissions?.sections['toggle-active'] && 
                                                        <Tooltip title={t('toggle-status')}>
                                                            <label className="switch mb-0">
                                                                <input type="checkbox" id={section.id} onChange={()=> toggleStatus(section.id)}  />
                                                                <span className="slider round" />
                                                            </label>
                                                        </Tooltip>
                                                    }
                                                    {Permissions?.sections?.update && <Tooltip title={t('edit')}>
                                                        <Link to={`${basename}/dashboard/sections/${section.id}/update`}>
                                                            <img src={edit_icon} alt="edit"/>
                                                        </Link>
                                                    </Tooltip>}
                                                    {Permissions?.sections?.destroy && <Tooltip title={t('delete')}>
                                                        <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={()=>setDeletedId(section.id)} />
                                                    </Tooltip>}
                                                </div>
                                            </td>
                                        </tr>
                                )})}
                            </tbody>
                        </table>
                        <DeleteModal onSubmitDelete={onSubmitDelete} name='section'></DeleteModal>
                    </div>}
                </div>
                {total > filterObj?.per_page && <div className='d-flex justify-content-end mt-auto'>
                    <Pagination  pagesNumber={pageCount} pageChangeHandler={handlepageChange} />
                </div>}
            </section>}
        </>
    )
}
