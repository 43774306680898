import { https } from './https';
import { api_url } from './../Configuration/config';

export async function getArticles(obj){
    return await https.get(`${api_url}admin/articles?${obj}`);
}

export async function getTrashedArticles(obj){
    return await https.get(`${api_url}admin/articles/trash?${obj}`);
}

export async function getArticle(id){
    return await https.get(`${api_url}admin/articles/${id}`);
}

export async function getArticlePerants(id){
    return await https.get(`${api_url}admin/articles/${id}/parents`);
}

export async function deleteArticle(id){
    return await https.post(`${api_url}admin/articles/${id}/destroy`);
}

export async function deleteTrashedArticle(id){
    return await https.post(`${api_url}admin/articles/${id}/destroy-permanently`);
} 

export async function createArticle(data){
    return await https.post(`${api_url}admin/articles/create`, data);
}

export async function updateArticle(id,data){
    return await https.post(`${api_url}admin/articles/${id}/update`, data);
}

export async function undeleteArticle(id){
    return await https.post(`${api_url}admin/articles/${id}/restore`);
}

export async function toggleArticleStatus(id){
    return await https.post(`${api_url}admin/articles/${id}/toggle-active`);
}

export async function assignArticlePermission(id, data){
    return await https.post(`${api_url}admin/articles/${id}/give-permission`, data);
}

export async function revokeArticlePermission(id, data){
    return await https.post(`${api_url}admin/articles/${id}/revoke-permission`, data);
}