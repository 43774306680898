import { t } from 'i18next';
import React ,{ useState , useEffect} from 'react'
import { supportedLanguages } from './../../../utilities/SupportedLanguages';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState ,convertToRaw} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getGeneralSettings, updateGeneralSettings } from '../../../Apis/generalSettings';
import { filterLanguage } from './../../../utilities/filterLang';
import { uploadImages } from '../../../Apis/uploadImages';

export default function PrivacyPolicy() {
    let [loader, setLoader]= useState(false);
    let [model ,setModel] = useState();
    let [editors ,setEditors ]= useState({})
    let [errors,setErrors]=useState({})
    
    useEffect(()=>{
        getGeneralSettings().then((res)=>{
            let thisEditors = {...editors};
            let result= res.data.data
            setModel(result.privacy_policy)
            supportedLanguages.map((language)=>{
                let lang = filterLanguage(result.privacy_policy, language.slug);
                if(lang.length){
                    //set values at editor state
                    const contentDataState = ContentState.createFromBlockArray(htmlToDraft(`${lang[0].name}`));
                    const editorDataState = EditorState.createWithContent(contentDataState);
                    thisEditors[language.slug] = editorDataState;
                }
                return true
            })
            setEditors(thisEditors);
        })
        // eslint-disable-next-line
    },[])
    const convertContentToHTML = (state) => {
        return draftToHtml(convertToRaw(state.getCurrentContent()));
    }

    const handleEditorChange = (state,lang) => {
        let thisEditors = {...editors}
        if(!thisEditors[lang]){ 
            thisEditors[lang]=EditorState.createEmpty()
        } 
        thisEditors[lang]=state;
        setEditors(thisEditors);
        let m =[...model]
        let existsLocales = m.filter((i) => {
            return i.locale === lang;
        })
        if(existsLocales.length){
            existsLocales[0]['name'] = convertContentToHTML(state);
        }else{
            m.push({'locale': lang});
            m.map((i) => {
                if(i.locale === lang){
                    i['name'] = convertContentToHTML(state);
                }
                return true;
            });
        }
        setModel(m) 
    }
    const [uploadedImages,setUploadedImages] = useState([])
    async function _uploadImageCallBack(file){
        const form = new FormData();
        form.append('image', file);
        let uploadedImages2 =  [...uploadedImages] ;
        let imageObject = {}
        return await uploadImages(form).then((response) => {
            imageObject = {
                file: file,
                localSrc: response.data.data.web_image,
            }
            uploadedImages2.push(imageObject);
            setUploadedImages(uploadedImages2);
            return  new Promise(
                (resolve, reject) => {
                    resolve({ data: { link: imageObject.localSrc } });
                }
            ) 
        }); 
    } 

    function submitHandler(e){
        e.preventDefault();
        setLoader(true);
        let formData=new FormData();
        let data=[...model]
        
        for(let i=0 ;i<= data.length ;i++){
            for( const key2 in data[i]){
                formData.append(`privacy_policy[${i}][${key2}]`,data[i][key2]);
            }
        }
        updateGeneralSettings(formData).then((res)=>{
                setLoader(false);
        }).catch((error)=>{
            let err = { ...errors }
            Object.entries(error.response.data.data).map(([key, value]) => {
                err[key] = value;
                setErrors(err);
                return true;
            });
        })
        
    }

    return (
        <div className='componentGlobalWrapper'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{t('privacy-and-policy')}</h5>
                <form className='pagesForm' onSubmit={submitHandler} >
                    {supportedLanguages?.map((language ,index)=>{
                        return <div key={index} className='d-flex flex-column mb-4'>
                                    <label htmlFor='content' className='formLabel'>{`${t('content-in')} (${t(`${language.name}`)})`}</label> 
                                    <div {...(language.slug === 'ar' ? {'dir':'rtl'}:{})}>
                                        <Editor 
                                            editorState={ model && editors[language.slug]}
                                            onEditorStateChange={(state) => handleEditorChange(state,language.slug)}
                                            toolbar =
                                                {{ 
                                                    image: { uploadCallback: _uploadImageCallBack} ,
                                                    alt: { present: true, mandatory: true }
                                                }}
                                        />
                                    </div>
                                </div>
                    })} 
                    {!loader ?
                            <div>
                                <button className='confirmBtn' type='submit' >
                                    {t('save-changes')}
                                </button>
                            </div>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {t('saving')}...
                            </button>
                        }
                </form>
            </div>
        </div>
    )
}
