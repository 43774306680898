import { t } from 'i18next';
import React, { useState, useEffect, useContext } from 'react';
import '../tables.scss';
import { useLocation } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import { deleteSection, getMainSections, reorderSections, toggleActivate } from '../../Apis/sections';
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import DeleteModal from '../../utilities/deleteModal';
import i18n from '../../i18n';
import moment from 'moment';
import search from "../../assets/icons/search.svg";
import '../forms.scss';
import { filterLanguage } from '../../utilities/filterLang';
import PermissionsContext from './../../Contexts/permissionsContext';
/* import styled from "@emotion/styled"; */
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function SectionsList() {
    let { Permissions } = useContext(PermissionsContext);
    const [allSections, setAllSections] = useState([]);
    const [deletedId, setDeletedId] = useState('')
    const { pathname } = useLocation();
    const { changeTitle } = useContext(TitleContext);
    const [activeSections, setActive] = useState([]);
    const [filterObj, setfilterObj] = useState({ per_page: 10, page: 1, search: '', status: '' });

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        if (i18n.language === 'ar') {
            moment.updateLocale('ar', {
                months: ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر']
            });
        } else {
            moment.updateLocale('en', {
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            });
        }
        // eslint-disable-next-line
    }, [i18n.language])

    useEffect(() => {
        getAllSections()
        changeTitle(t(`main-sections`), Permissions?.sections?.create && { url: 'sections/create', text: t('add-section') });
        // eslint-disable-next-line
    }, [])

    /* get all articles */
    function getAllSections() {
        let filter = { ...filterObj }
        let obj = `per_page=${filter.per_page}&search=${filter.search}&status=${filter.status}&page=${filter.page}`;
        console.log(obj)
        getMainSections(obj).then((res) => {
            setAllSections(res.data.data);
            console.log(res.data.data)
        }).catch((err) => (console.log(err)))
    }

    /* delete article */
    const onSubmitDelete = async () => {
        return await deleteSection(deletedId).then((res) => {
            getAllSections()
            return 'done';
        }).catch((err) => {
            return err.response.data.message
        })
    }

    function changeFilterObj(e) {
        let thisObj = { ...filterObj }
        thisObj[e.target.name] = e.target.value.trim();
        thisObj.page = 1
        setfilterObj(thisObj);
    }

    function getFiltered() {
        getAllSections()
    }
    
    const [activateMessage, setactivateMessage] = useState('');
    function toggleStatus(id) {
        toggleActivate(id).then((res)=>{
            getAllSections()
        }).catch((err)=>{
            setactivateMessage(err.response.data.message);
            var alertTrigger = document.getElementById(`alert`)
            alertTrigger.classList.add('show')
            getAllSections()
        })
    }

    function removeAlert(x) {
        let alert = document.getElementById(x)
        alert.classList.remove('show')
    }

    /* ************************************************* */
    const [orders ,setOrders] = useState([]);

    function Tr({ section, index }) {
        return (
            <Draggable width='100%' draggableId={`sec${section.id}`} index={index}>
                {provided =>
                    {   let lang = i18n.language
                        let obj = filterLanguage(section.locales, lang)[0];
                    return(<tr /* key={index} */ ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        {/* image */}
                        <td className='sliderImg' >
                            <img src={`${section?.image?.url}`} alt="slider" />
                        </td>
                        {/* name */}
                        <td className='desc'>
                            <div className='descriptionTd'>
                                {section?.locales && obj ? obj.name : section.locales[0]?.name}

                            </div>
                        </td>
                        {/* sub sections */}
                        <td>
                            {
                                section?.sections === true ?
                                    <Link to={`${basename}/dashboard/sections/${section.id}/sub-sections`}>
                                        {t('show-all')}
                                    </Link>
                                    :
                                    '-'
                            }
                        </td>
                        {/* date */}
                        <td>
                            {moment(section.created_at).format('DD-MMMM-YYYY')}
                        </td>
                        <td>
                            <div className='position-relative' >
                                {Permissions?.sections['toggle-active'] &&
                                    <Tooltip title={t('toggle-status')}>
                                        <label className="switch mb-0 " >
                                            <input type="checkbox"  id={'section-'+section.id}
                                                checked={activeSections.includes(section.id)}
                                                onChange={()=> toggleStatus(section.id)}  />
                                            <span className="slider round" />
                                        </label>
                                    </Tooltip>
                                }
                                {Permissions?.sections?.update && <Tooltip title={t('edit')}>
                                    <Link to={`${basename}/dashboard/sections/${section.id}/update`}>
                                        <img src={edit_icon} alt="edit" />
                                    </Link>
                                </Tooltip>}
                                {Permissions.sections?.destroy && <Tooltip title={t('delete')}>
                                    <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal'
                                    onClick={()=>setDeletedId(section.id)} />
                                </Tooltip>}

                            </div>
                        </td>
                    </tr>)}
                }
            </Draggable>
        );
    }

    function Trs() {
        const blocks = [...allSections];
        return blocks.map((item, index) => (
            <Tr section={item} index={index} key={item.id} />
        ));
    }

    function Column() {
        return (
            <Droppable droppableId="column-1">
                {provided => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    <Trs />
                    {provided.placeholder}
                </tbody>
                )}
            </Droppable>
        );
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function reorderApi(arr){
        reorderSections(arr)
    }

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const quotes = reorder(
            allSections,
            result.source.index,
            result.destination.index
        );

        let o = []
        setAllSections(quotes ,
            quotes.map((section, index)=>{
            let obj = {'id':section.id ,'order':index+1}

            o.push(obj);
            return true
        }));
        setOrders(o , reorderApi(o))
    }

    useEffect(() => {
        let statuses = [];
        allSections && allSections.length && allSections.map((sec) => {
            if(sec.status === 'active')
                statuses.push(sec.id)
        });
        setActive(statuses);
    },[allSections])

    /* ******************************************************************************* */

    return (
        <>
            {Permissions?.sections && <section className='componentGlobalWrapper d-flex flex-column'>
                {/*  */}
                <div>
                    <div className='d-flex justify-content-start alig-items-center w-100 mb-3 flex-wrap'>
                        <div className='searchActionsBar mt-0 mb-0'>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj} />
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                        </div>
                        {/*** apply search btn  ***/}
                        <button className='applySearchBtn' onClick={getFiltered}>{t('search')}</button>
                        <div id={`alert`} className="alert alert-danger alert-dismissible fade mb-0 " role="alert">
                            <button type="button" onClick={() => removeAlert(`alert`)} className="btn-close" />
                            <span>{activateMessage}</span>
                        </div>
                    </div>
                    <div className='tableWrapper'>
                        {Permissions?.sections?.show &&
                        allSections && <table className="w-100 table table-striped ">
                            <thead>
                                <tr>
                                    <th>{t('image')}</th>
                                    <th>{t('name')}</th>
                                    <th>{t('sub-sections')}</th>
                                    <th>{t('created-at')}</th>
                                    <th>{t('actions')}</th>
                                </tr>
                            </thead>
                            {/* <tbody> */}
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Column />
                                </DragDropContext>
                            {/* </tbody> */}
                        </table>}
                    </div>
                </div>
                <DeleteModal onSubmitDelete={onSubmitDelete} name='section'/>
            </section>}
        </>
    )
}
