import { https } from './https';
import { api_url } from './../Configuration/config';

//get settings
export async function getGeneralSettings(){
    return await https.get(`${api_url}admin/settings`);
}

//update settings
export async function updateGeneralSettings(data){
    return await https.post(`${api_url}admin/settings/update`, data);
}
