import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import TitleContext from '../../Contexts/TitleContext';
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import { deleteAdmin, getAdmins } from '../../Apis/admins';
import { basename } from '../../Configuration/config';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import DeleteModal from '../../utilities/deleteModal';
import '../tables.scss'
import { t } from 'i18next';
import PermissionsContext  from './../../Contexts/permissionsContext';
import { useTranslation } from 'react-i18next';

export default function AdminsList() {
    let {Permissions} = useContext(PermissionsContext);
    const { pathname } = useLocation();
    const {i18n} = useTranslation(); 
    let {changeTitle} = useContext(TitleContext);
    const [allAdmins , setAllAdmins] = useState([]);
    const [adminId ,setAdminId] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get admin id
    function getId(id){
        setAdminId(id)
    }

    // get all admins
    function getAllAdmins(){
        let myallAdmins = [...allAdmins];
        getAdmins().then(res => {
            myallAdmins = res.data.data;
            setAllAdmins(myallAdmins);
        }).catch(err => 
            console.log(err)
        )
    }

    useEffect(()=>{
        changeTitle(t('admins'), Permissions?.admins?.create && {url: 'admins/create', text: t('add-admin')});
        getAllAdmins();
        // eslint-disable-next-line
    } , [i18n.language]);

    // delete admin
    const onSubmitDelete = async ()=>{
        return await deleteAdmin(adminId).then(res =>{
            getAllAdmins();
            return 'done';
        }).catch(err => {
            return err.response.data.message; ;
        })
    }

    return (
        <>
            {Permissions?.admins?.show &&<section>
                <div style={{"borderRadius" : "8px" , "border" : "1px solid #DFE0EB"}} className='tableWrapper'>
                    <table className="w-100 table table-striped border-0">
                        <thead>
                            <tr>
                                <th>{t('username')}</th>
                                <th>{t('name')}</th>
                                <th>{t('email')}</th>
                                <th>{t('role')}</th>
                                <th>{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allAdmins && allAdmins.map((admin , index)=>(
                                <tr key={index}>
                                    <td>{admin.username}</td>
                                    <td style={{"width" : "22%"}}>{admin.name}</td>
                                    <td style={{"width" : "18%"}}>{admin.email}</td>
                                    <td style={{"width" : "15%"}}>
                                        <div className={`adminRoleLabel p-2`}>
                                            {admin?.role?.name ?  admin.role.name : 'no role'}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {Permissions?.admins?.update && <Tooltip title={t('edit')}>
                                                <Link to={`${basename}/dashboard/admins/${admin.id}/update`}>
                                                    <img src={edit_icon} alt="edit"/>
                                                </Link>
                                            </Tooltip>}
                                            {Permissions?.admins?.destroy && <Tooltip title={t('delete')}>
                                                <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={()=>getId(admin.id)}/>
                                            </Tooltip>}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <DeleteModal onSubmitDelete={onSubmitDelete} name='admin'></DeleteModal>
                </div>
            </section>}
        </>
    )
}
