export const setArticleData = (model, setModel, ele, property, locale=false, language=null ) =>{
    let newData = Object.assign({}, model);

    if(locale){
        let existsLocales = newData.locales.filter((i) => {
            return i.locale === language;
        })
        if(existsLocales.length){
            existsLocales[0][ele.target.name] = ele.target.value;
        }else{
            newData.locales.push({'locale': language });
            newData.locales.map((i) => {
                if(i.locale === language){
                    i[ele.target.name] = ele.target.value;
                }
                return true;
            });
        }
    }else{
        if (ele.target.name === 'image') {
            if(ele.target.files.length){
                newData['image'] = ele.target.files[0];
            }
        }else if(ele.target.name === 'status'){
            newData[property] = ele.target.checked ? 'active' : 'inactive';
        }else if(ele.target.name === 'section'){
            if(ele.target.value !== '0'){
                newData[property] = ele.target.value;
            }
        }else{
            newData[property] = ele.target.value;
        }
    }
    setModel(newData);
}