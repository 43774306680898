import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import TitleContext from '../../../Contexts/TitleContext';
import "./WebsitePages.scss";
import rightarrow from "../../../assets/icons/rightarrow.svg"
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { basename } from './../../../Configuration/config';

export default function WebsitePages() {
    let {changeTitle} = useContext(TitleContext);
    let {i18n} =useTranslation();
    useEffect(()=>{
        changeTitle(t(`pages`));
        // eslint-disable-next-line
    } , [i18n.language]);

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <>
        <section className='componentGlobalWrapper websitePages'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{t('pages-settings')}</h5>
            </div>

            <div>
                <div className='overviewPage'>
                    <p>{t('home')}</p>
                    <NavLink to={`${basename}/dashboard/appearance/pages/home`}>
                        <img src={rightarrow} className='pageArrow' alt="arrow"/>
                    </NavLink>
                </div>
                <div className='overviewPage'>
                    <p>{t('privacy-and-policy')}</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/privacy-policy`}>
                        <img src={rightarrow} className='pageArrow' alt="arrow"/>
                    </NavLink>
                </div>
                <div className='overviewPage'>
                    <p>{t('terms')} &#38; {t('conditions')}</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/terms-and-conditions`}>
                        <img src={rightarrow} className='pageArrow' alt="arrow"/>
                    </NavLink>
                </div>
                <div className='overviewPage'>
                    <p>{t('contact-us')}</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/contact-us`}>
                        <img src={rightarrow} className='pageArrow' alt="arrow"/>
                    </NavLink>
                </div>
            </div>

        </section>
        </>
    )
}
