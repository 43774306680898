import { https } from './https';
import { api_url } from './../Configuration/config';

//get scripts
export async function getScripts(){
    return await https.get(`${api_url}admin/scripts`);
}

//get script by id
export async function getScript(id){
    return await https.get(`${api_url}admin/scripts/${id}`);
}

//update script
export async function updateScript(id,data){
    return await https.post(`${api_url}admin/scripts/${id}/update`,data);
}
//toggle script
export async function toggleActiveScript(id){
    return await https.post(`${api_url}admin/scripts/${id}/toggle-active`);
}